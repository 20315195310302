import * as React from "react"
import { useRef, useEffect, useLayoutEffect, useState, useReducer, useCallback } from "react"
import { generate_id } from "../blog/BlogHeaderLink"

export interface TextDictionary {
	[index: string]: SVGTextElement
}

interface SVGTextProps {
	children? : string[]
	x : number,
	y : number,
	maxLineWidth : number,
	fontSize : number,
	string : string,
	dx? : number | string,
	dy? : number | string,
	fill? : string,
	stroke? : string,
	textAnchor? : string,
	fontFamily? : string,
	fontWeight? : number,
	fontLeading? : number,
	visible? : boolean,
	topLevel? : boolean,
	degrees? : number,
	get_height : ( key:string, height:number )=>void 
	reference : React.RefObject<TextDictionary>
	text_nodes? : React.RefObject<SVGTextElement[]>
	opacity? : number,
}

interface SVGTextState {
	tokens : string[],
	quote  : boolean,
	string : string,
	lines  : SVGLine[]
}

interface SVGLine {
	tokens : string[],
	length : number,
}

export const SVGText = ( props : SVGTextProps ) => {

	//const [ ref, { x, y, width, height } ] = useDimensions({ liveMeasure: false });
	const raw_tokens = useRef<SVGTextElement[]>([])

	const {
		fontFamily  = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;',
		fontSize    = 12,
		fontWeight  = 400,
		fontLeading = 1.1,
		textAnchor  = "middle",
		visible     = true,
		fill        = "darkgray",
		stroke      = "none",
		opacity     = 1.0
	} = props

	const [tokens, setTokens] = useState([])
	const [quote, setQuote]   = useState<boolean>(false)
	const [string, setString] = useState("")
	const [lines, setLines]   = useState([])

	const [, forceUpdate]     = useReducer(x => x + 1, 0)

	const token_callback = useCallback( newNode => {
		
		// Gets called every time the node refreshes.
		
		//setNode(processNode(newNode));
	}, []);

	// useEffect( () => {
	// 	console.log("SVG Text Mounting")
	// 	console.log()
	// }, [])

	// useEffect( () => {
	// 	console.log("Raw Tokens Updated")
		
	// }, [raw_tokens.current] )

	useLayoutEffect( () => {
		//console.log("Props String Updating")
		/* props.string !== null && props.string !== undefined ? setTokens(props.string.split(/\s+/)) : null
		props.string !== null && props.string !== undefined ? setString(props.string) : null */

		props.string !== string ? (
			setString(props.string),
			setTokens(props.string.split(/\s+/)),
			setLines([])
		) : null

	}, [props.string])

	// useLayoutEffect( () => {
	// 	console.log("String Updating")
	// 	/* props.string !== null && props.string !== undefined ? setTokens(props.string.split(/\s+/)) : null
	// 	props.string !== null && props.string !== undefined ? setString(props.string) : null */

	// }, [string]) 

	useLayoutEffect( () => {
		lines.length === 0 ? parse_tokens_into_lines() : null
		forceUpdate()
	}, [tokens]) 

	useLayoutEffect( () => {

		setTokens( props.string.split(/\s+/) ),
		setLines([])

	}, [props.maxLineWidth] )

	const parse_tokens_into_lines = () => {

		let open_quotes = false

		var number_of_lines = 1

		let current_line : SVGLine = {
			tokens : [],
			length : 0,
		}

		let split_lines : SVGLine[] = []

		raw_tokens !== undefined ? raw_tokens.current.filter( token => token !== undefined && token !== null ).forEach( ( token, index ) => {
			
			let current_node_length = token !== null ? token.getComputedTextLength() : 0

			// Check to see if this is at the start of the text layout
			if ( number_of_lines == 1 && current_line.length == 0 && token !== null ) {

				// Add a new tspan to hold the text for the initial line
				//current_line = <tspan id={line_id} data-lineNumber={number_of_lines} x={this.props.x}></tspan>
				current_line.tokens.push( token.textContent )
				current_line.length = current_line.length + current_node_length 

			// Assume that this is any token other than the first one 
			} else {

				// Check if this is the start of a new line
				if ( current_line.length !== 0 ) {

					// Check if the token ends with possessive punctuation - /\b[-.,()&$#!\[\]{}"']+\B|\B[-.,()&$#!\[\]{}"']+/
					if ( token.textContent.match(/\b['´]+\B|\B['´]+/) ) {

						if ( open_quotes === true) { 
							// In which case, add the token to the current line without a proceeding space
							current_line.tokens.push( token.textContent )
							current_line.length = current_line.length + current_node_length
							open_quotes = false
						
						} else {
							current_line.tokens.push( " "+token.textContent )
							current_line.length = current_line.length + current_node_length + (1 * props.fontSize)
						}

					// Otherwise add space before adding the token    
					} else {

						// TODO : Work out how to calculate the width of space.
						current_line.tokens.push( " "+token.textContent )
						current_line.length = current_line.length + current_node_length + (1 * props.fontSize)

					}

				// This is not the start of a new line 
				} else {

					// Check if the token ends in a possessive apostrophe 
					if ( token.textContent.match(/\b['´]+\B|\B['´]+/) ) {

						// In which case, add the token to the current line without a proceeding space
						current_line.tokens.push( token.textContent )
						current_line.length = current_line.length + current_node_length

					// Otherwise add space before adding the token    
					} else {

						current_line.tokens.push( " "+token.textContent )
						current_line.length = current_line.length + current_node_length + (1 * props.fontSize * props.fontLeading)

					}

				}

			}

			// Need to get width of the text string, to determine if line break is required
			if ( ( current_line.length >= props.maxLineWidth ) && (index < raw_tokens.current.length) ) {
				//console.log("Current Line is Longer Than Max")
				let last_item = current_line.tokens.pop();
				
				// Push the current line to the lines store
				split_lines.push(current_line)

				// Reset the 'current line' store to 0
				current_line = {
					tokens : [],
					length : 0,
				}

				current_line.tokens.push(last_item.trim())
				current_line.length = current_node_length

			} 

			if ( index === raw_tokens.current.length-1 ) {
				split_lines.push(current_line)
			}

		}) : null
		
		setLines(split_lines)

		props.get_height !== undefined ? props.get_height( generate_id( props.string ), split_lines.length * props.fontSize ) : null
	
	}

	const get_text_alignment = ( (x: SVGTextElement) => {

		return "middle"

	})

	const element_did_change = useCallback( (element : SVGTextElement ) => {

		// Adds this element to the ref dictionary
		element != null ? props.reference.current[ generate_id( props.string ) ] = element : null

	}, [props])

	return (<React.Fragment>
			{ lines !== undefined && lines.length > 0 ? [
				<text
					id={ generate_id(props.string)+"-text" }
					ref = { ( element ) => element_did_change( element ) }
					key = { generate_id(props.string)+"-text" }
					fontFamily = { props.fontFamily }
					fontSize = { props.fontSize }
					fontWeight = { props.fontWeight }
					textAnchor = { props.textAnchor }
					fill = { props.fill }
					stroke = { props.stroke }
					opacity = { props.opacity }
					//x = { props.x } 
					//y = { this.props.y - ( this.state.lines.length * (this.props.fontSize * this.props.fontLeading) / 2 )  }
					//y = { props.y }
				>
				{ lines.map( ( line:SVGLine, index ) =>
					<tspan 
						id = {"line_"+index}
						key = {"line_"+index} 
						x = { props.x } 
						y = { props.y !== undefined ? props.fontLeading !== undefined ? props.y + ( index * (props.fontSize * props.fontLeading) ) : props.y + ( index * (props.fontSize * 1.1) ) : props.y }
						dy = { props.fontLeading !== undefined ? props.fontSize * props.fontLeading : props.fontSize }
						opacity = { props.opacity }
					>{ line.tokens.join(' ') }</tspan>
				)}
				</text>
			] : tokens.map( ( token, index ) => [
				<text
					key = { token } 
					ref = { (element) => {
						element !== null ? raw_tokens.current[index] = element : null
					} }
					x = { props.x }
					y = { props.y }
					fontFamily = { props.fontFamily }
					fontSize = { props.fontSize }
					fontWeight = { props.fontWeight }
					textAnchor = { props.textAnchor }
					dy = { props.fontSize }
					opacity = { 1 }
					spacing = { 1.2 }
				>
					{ token }
				</text>]
				)
			}
		</React.Fragment>
	)
}
