import React, { useRef, useState, useEffect } from 'react'

import { generate_id } from "./BlogHeaderLink"

import { logEvent } from "firebase/analytics"
import { FirebaseContext } from "../../components/firebase/firebase-context"
import { text } from 'd3'

interface BlogHeading {
	depth : number,
	value : string,
}

interface DraftWarningProps {
	
}

interface DraftWarningState {

}

const isBrowser = typeof window !== "undefined"

export const DraftWarning = ( props : DraftWarningProps ) => {

	const container_element = useRef(null);

	const container_style : React.CSSProperties = {
		width : "100%",
		display : "flex",
		flexDirection : "row",
		backgroundColor : "rgba(255, 202, 41, 0.9)"
	}

	const content_style : React.CSSProperties = {
		width : "100%",
		display : "flex",
		flexDirection : "row", 
		marginLeft : "auto",
		marginRight : "auto",
		justifyContent : "center",
		paddingLeft : "10%",
		paddingRight : "10%",
		paddingTop : "1%",
		paddingBottom : "1%",
		textAlign : "center"
	}

	return (
		<section id="blog-draft-warning" className="blog-draft-warning" ref={ container_element } style={ container_style }>
			<div className="content" style={ content_style } >
				<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width={75} viewBox="0 0 72.19 63.5"><path d="M36.02,4l32.1,55.5H3.93L36.02,4Zm0-4c-1.4,0-2.7,.8-3.5,2L.52,57.5c-.7,1.2-.7,2.8,0,4s2,2,3.5,2H68.13c1.4,0,2.7-.8,3.5-2,.8-1.2,.7-2.8,0-4L39.52,2c-.8-1.2-2.1-2-3.5-2Z" fill="#000"/><path d="M36.02,41.2c-1.1,0-2-.9-2-2V22.5c0-1.1,.9-2,2-2s2,.9,2,2v16.7c0,1.1-.9,2-2,2Z" fill="#000"/><path d="M38.02,50.2c0,2.67-4,2.67-4,0s4-2.66,4,0" fill="#000"/></svg>
				<div style={ { display : "flex", flex : "row", flexWrap : "wrap", justifyContent : "center" } }>
					<h4 style={ { fontFamily : "'Milo Slab OT Regular', Milo-Slab, sans-serif", color : "#333", fontWeight : 400, marginTop : 0, marginBottom : 0 } }>This post is currently in draft</h4>
					<p style={{marginBottom:10}}>Content is not final, and may change at any time. The diagrams, which are drawn programmatically, are currently <a href="https://en.wikipedia.org/wiki/Software_release_life_cycle#Beta" target="_blank">in beta</a> - and should not be considered final. Known issues exist around their legibility on non-desktop devices.</p>
				</div>
			</div>
		</section>
	)

}

export default DraftWarning