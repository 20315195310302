import React, { useRef, useState, useEffect } from 'react'

interface Author {
	link : string,
	name : string,
	value : string,
}

enum ReferenceType {
	inline = "inline",
	text   = "text",
	blockquote = "blockquote"
}

interface BlogInlineReferenceProps {
	type : ReferenceType
	id? : string
	text? : string
}

interface BlogInlineReferenceState {

}

export const BlogInlineReference = ( props : BlogInlineReferenceProps ) => {

	const container_element = useRef(null);

	const [inline, set_inline]             = useState<string>("")
	const [blockquote, set_blockquote]     = useState<string>("")
	const [citation, set_citation]         = useState<string>("")
	const [source_title, set_source_title] = useState<string>("")
	const [source_year, set_source_year]   = useState<string>("")
	const [authors, set_authors]           = useState<Author[]>([])
	
	// Set state
	// const [state, setState] = useState<{ stateShape: BlogInlineReferenceState }>()

	// console.log(props)

	// Mount / unmount useEffect
	useEffect( () => {

		if ( props.id === undefined ) {
			return 
		}

		fetch("https://local.fergusbisset.com/wp-json/reifier/v1/citations/id/?search="+props.id, {
			"method": "GET",
			"headers": {
				"Accept": "application/json"
			}
		})
		.then( (res) => res.json() )
		.then( (json) => {
			console.log(json)

			json.inline !== undefined       ? set_inline(json.inline) : null
			json.blockquote !== undefined   ? set_blockquote(json.blockquote) : null
			json.authors !== undefined      ? set_authors(json.authors) : null
			json.source_title !== undefined ? set_source_title(json.source_title) : null
			json.source_year !== undefined  ? set_source_year(json.source_year) : null

		})
	
	}, [props.id])

	const get_value = () => {

		switch(props.type) {
			case ReferenceType.inline:
				return (
					<span className="inline-reference" ref={ container_element }>
						{ inline }
					</span>
				)
			case ReferenceType.text:
				return props.text !== undefined ? (
					<span className="inline-reference" ref={ container_element }>
						{ props.text }
					</span>
				) : null
			case ReferenceType.blockquote:
				return (
					<blockquote className="inline-blockquote" ref={ container_element }>
						{ blockquote }
						<cite> { inline }</cite>
					</blockquote>
				)
			default:
				return null
		}

	}

	return get_value()

}

export default BlogInlineReference