import React, { useRef, useState, useEffect } from 'react'

interface BlogCommentProps {
	children: JSX.Element
}

interface BlogCommentState {

}

export const BlogComment = ( props : BlogCommentProps ) => {

	const container_style : React.CSSProperties = {
		width : "100%",
		display : "flex",
		flexDirection : "row",
		backgroundColor : "#eee",
	}

	const content_style : React.CSSProperties = {
		width : "100%",
		display : "flex",
		flexDirection : "row", 
		marginLeft : "auto",
		marginRight : "auto",
		justifyContent : "center",
		paddingLeft : "10%",
		paddingRight : "10%",
		textAlign : "center",
		alignItems : "center",
	}

	const text_style : React.CSSProperties = {
		display : "flex",
		flex : "row",
		flexWrap : "wrap",
		textAlign : "left",
		paddingLeft : "5%",
		fontFamily : "'Milo Slab', Milo-Slab, san-serif",
		fontWeight : 300,
		fontSize : "1.1rem",
		width : "95%"
	}

	return (
		<section id="blog-discussion-comment" className="blog-discussion-comment" style={ container_style }>
			<div className="content" style={ content_style } >
			<svg id="Icons" xmlns="http://www.w3.org/2000/svg" width={50} viewBox="0 0 80 80"><polygon points="10.97 10.17 10.97 67.08 19.25 67.08 30.93 79 42.62 67.08 79 67.08 79 10.17 10.97 10.17" fill="none" stroke="#555" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/><polyline points="10.97 54.9 1 54.9 1 1 71.47 1 71.47 9.96" fill="none" stroke="#555" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/></svg>
				<div style={ text_style }>
					{props.children}
				</div>
			</div>
		</section>
	)

}

export default BlogComment