import * as React from 'react';
import { useRef, useState, useEffect, useReducer } from 'react'
import { Language, LanguageSelector, LanguageContext } from '../language/Language'

import { hierarchy as Hierarchy, HierarchyNode, HierarchyPointNode, tree as Tree, line, curveBumpX, cluster as Cluster } from 'd3'
import { SVGText } from '../svg/new-svg-text'
import { generate_id } from './BlogHeaderLink'
import { gsap, Power1, CSSPlugin } from 'gsap'
gsap.registerPlugin(CSSPlugin)

interface TranslatedString {
	english : string
	svenska : string
}

interface ReifierModelInterface {
	title : TranslatedString,
	description : TranslatedString,
	components : { [key:string] : ReifierModelInterface }
	level : number,
	id : number,
}

enum CirclePole {
	north = "north",
	south = "south",
	false = "false"
}

export enum ModelType {
	list           = "list",
	linked_list    = "linked_list",
	cluster        = "cluster",
	radial_cluster = "radial_cluster",
	radial_tree    = "radial_tree",
	tree           = "tree",
	indented_tree  = "indented_tree",
	directed_graph = "directed_graph",
	tree_map       = "tree_map",
	partition      = "partition",
	pack           = "pack",
	force          = "force",
	venn           = "venn",
}

interface NodePosition {
    x: number;
    y: number;
    parent_x: number;
    parent_y: number;
    angle: {
		radians : number,
		degrees : number
	}
}

interface MultiLingualString {
	[key: string] : string
	english : string
	svenska : string
}

export interface TextDictionary {
	[index: string]: number|SVGTextElement
}

export interface LineHeightDictionary {
	[index: string]: number
}

enum SVGModelType {
	hierarchy = "hierarchy",
	indented = "indented"
}

interface RadialHierarchyProps {
	language : Language
	json : any
	rotation? : number
	levels? : number
	font_size? : number
	radius? : number
	line_width? : number
	type? : SVGModelType
}

interface RadialHierarchyState {
    width : number,
	height : number,
	viewBox : string,
}

const defaultProps = {
	language : Language.english,
	json : {},
	rotation : 0,
	levels : 2,
	font_size : 16,
	radius : 3.5,
	type : SVGModelType.hierarchy,
}

export const ReifierModel = ( { defaultProps, ...props } ) => {
	
	const node_text_boxes = useRef<TextDictionary>({})

	const div_element = useRef<HTMLDivElement>(null)
	const svg_element = useRef<SVGSVGElement>(null) 
	const svg_container_group = useRef<SVGGElement>(null) 

	//const [svg_orig_dimensions, set_svg_orig_dimensions] = useState<SVGDimension>({ width: 0, height: 0 })

	//const [state, setState] = useState<{state: RadialHierarchyState}>();

	const [width, setWidth]     = useState(0)
	const [height, setHeight]   = useState(0)
	const [viewBox, setViewBox] = useState("0, 0, 0, 0")
	const [container_dimensions, setContainerDimensions] = useState({ width:0, height: 0 })

	const [, forceUpdate] = useReducer(x => x + 1, 0)

	const [radius, setRadius] = useState(0)

	const [nodes, setNodes] = useState<HierarchyPointNode<any>[]>([])

	const [line_heights, set_line_heights] = useState<TextDictionary>({})

	const default_font_size = 16

	//const [max_depth, setMaxDepth] = useState(1)

	// Mount / unmount useEffect
	useEffect( () => {

		let max_depth = props.levels !== undefined ? props.levels : 1

		// Add event listener.
		window.addEventListener( 'resize', update_the_size )
		
		// Run update the size on first load
		update_the_size()

		// Recursive function to convert component objects to arrays, for D3 hierarchy
		const recurse_child = ( d:any ) => {
			d.components = d.components !== undefined ? Object.values(d.components).map( recurse_child ) : null
			return d
		}

		let model_title = props.json.title !== undefined ? props.json.title[props.language] : props.json.meta.title[props.language]

		var output = {
			"title": {
				"english" : model_title,
				"svenska" : model_title,
			},
			"components": Object.values(props.json.components).map( recurse_child )
		}

		let hierarchy = Hierarchy<any>(output, d => d.components)

		//console.log(hierarchy)

		let rad_tree = Cluster<any>()
		rad_tree.size( [360, width ] )
		
		rad_tree.separation( ( a:HierarchyNode<any>, b:HierarchyNode<any> ) => {
			return a.parent == b.parent && b.depth === max_depth && a.depth === max_depth ? 10 : 2
		})

		hierarchy.sum( (node) => {
			// if ( node.parent === undefined ) {
			// 	return 0
			// }
			return node.components.length > 0 ? node.components.length : 1
		} )

		let graph = rad_tree( hierarchy )

		let nodes = graph.descendants().filter( (node, index) => {
			return node.depth <= max_depth ? true : false
		})

		setNodes( nodes )
		
		return () => {
			window.removeEventListener('resize', update_the_size)
		}

	}, [])

	useEffect( () => {

		svg_transform()

	}, [width, node_text_boxes] )

	const getBBox = (element, withoutTransforms:boolean = false, toElement?) => {

		var svg = element.ownerSVGElement;

		if (!svg) {
			return { x: 0, y: 0, cx: 0, cy: 0, width: 0, height: 0 };
		}

		var r = element.getBBox(); 

		if (withoutTransforms) {
			return {
			x: r.x,
			y: r.y,
			width: r.width,
			height: r.height,        
			cx: r.x + r.width / 2,
			cy: r.y + r.height / 2
			};
		}

		var p = svg.createSVGPoint(); 

		var matrix = (toElement || svg).getScreenCTM().inverse().multiply(element.getScreenCTM()); 

		p.x = r.x;
		p.y = r.y;
		var a = p.matrixTransform(matrix);

		p.x = r.x + r.width;
		p.y = r.y;
		var b = p.matrixTransform(matrix);

		p.x = r.x + r.width;
		p.y = r.y + r.height;
		var c = p.matrixTransform(matrix);

		p.x = r.x;
		p.y = r.y + r.height;
		var d = p.matrixTransform(matrix);

		var minX = Math.min(a.x, b.x, c.x, d.x);
		var maxX = Math.max(a.x, b.x, c.x, d.x);
		var minY = Math.min(a.y, b.y, c.y, d.y);
		var maxY = Math.max(a.y, b.y, c.y, d.y);

		var width = maxX - minX;
		var height = maxY - minY;

		return {
			x: minX,
			y: minY,
			width: width,
			height: height,        
			cx: minX + width / 2,
			cy: minY + height / 2
		};
	}

	const does_svg_element_extend_bounds = () => {

		if (svg_element === undefined || svg_container_group == undefined) {
			return
		}
		console.log(props.json.title["english"])
		console.log(svg_element.current?.getBoundingClientRect())
		console.log(svg_container_group.current?.getBoundingClientRect())

	}

	const get_transform = (element) => {

		if ( svg_element === undefined ) {
			return undefined
		}

		var point = svg_element.current !== null ? svg_element.current.createSVGPoint() : undefined

		var transform = element.getTransformToElement(svg_element.current);
		return point !== undefined ? point.matrixTransform(transform) : undefined
	}

	const svg_transform = ( () => {

		let tl = gsap.timeline()

		let section_width = div_element.current != undefined ? div_element.current.getBoundingClientRect().width : 0
		let section_height = div_element.current != undefined ? div_element.current.getBoundingClientRect().height : 0

		// console.log("Section Width: "+section_width)
		// console.log("Section Height: "+section_height)
		// console.log(props.json.title["english"])
		
		let group_bounds = getBBox(svg_container_group.current)
		let bottom = group_bounds.y + group_bounds.height

		if ( bottom > section_height ) {
			tl.set(svg_container_group.current, {
				y: -group_bounds.y,
				svgOrigin: `0% 0%`,
			})
		}
		

		// let height = section_width / 4 * 3

		// // section_width > height ? console.log("width is greater than height") : console.log("height is greater than width")

		// let scale_x = orig_svg_width >= section_width ? section_width / orig_svg_width : 1

		// //console.log(scale_x)

		// let scale_y = orig_svg_height >= height ? height / orig_svg_height : orig_svg_height / height

		// //console.log(scale_y)

		// let scale = section_width >= height ? scale_x : scale_y

		// //console.log(scale)

		// let diff_x = section_width - (orig_svg_width * scale)
		// let diff_y = height - (orig_svg_height * scale)

		// // console.log(diff_x)
		// // console.log(diff_x)

		// tl.set(svg_container_group.current, {
		// 	scale: scale,
		// 	x: diff_x / 2,
		// 	y: diff_y / 2,
		// 	svgOrigin: `0% 0%`,
		// })

		// let strings = svgTransformStrings

		// if ( return_string != "" ) {
		// 	strings[key] = return_string 
		// }

		// var dimensions = svg_orig_dimensions

		// if ( dimensions === undefined ) {
		// 	dimensions = {
		// 		width : orig_svg_width,
		// 		height : orig_svg_height,
		// 	}
		// }

		// tl.to(svg_container_group.current, {
		// 	opacity : 1,
		// })

		// //setSvgTransformStrings(strings)
		// set_svg_orig_dimensions(dimensions)

	})

	const get_container_dimensions = ( () => {
		if (svg_container_group.current) {
		setContainerDimensions({
			width: svg_container_group.current.getBBox().width,
			height: svg_container_group.current.getBBox().height
		});
		}
	})

	const update_the_size = ( () => {

		let width = div_element !== undefined && div_element.current !== null ? div_element.current.clientWidth : 0
		let height = width / 16 * 9

		setWidth( width )
		setHeight( height )
		setViewBox( "0 0 "+ width + " "+ height )
		props.radius !== undefined ? setRadius( width/props.radius ) : setRadius( width/3 )
		get_container_dimensions()

	} )

	const project = ( node:HierarchyPointNode<any> ) => {

		let parent:NodePosition|null = node.depth > 1 && node.parent !== null ? project( node.parent ) : null

		let parent_x:number = parent !== null && node.depth > 1 ? parent.x : width / 2
		let parent_y:number = parent !== null && node.depth > 1 ? parent.y : height / 2

		let rotation = props.rotation !== undefined ? props.rotation : 0

		let angle = (node.x-150) / 180 * Math.PI - rotation

		let _radius = props.radius !== undefined ? node.depth === 1 ? radius / props.radius : radius / 2.5 : node.depth === 1 ? radius / 3 : radius / 2

		return {
			x : _radius * Math.cos( angle ) + parent_x, 
			y : _radius * Math.sin( angle ) + parent_y,
			parent_x : parent_x,
			parent_y : parent_y,
			angle : {
				radians : angle,
				degrees : angle * (180/Math.PI)
			}
		}
	}

	const get_text_height = ( ( key:string, height:number ) => {


		if ( key === undefined || key === null || key === "" ) {
			return
		}

		node_text_boxes.current[key] = height

		set_line_heights(node_text_boxes.current)

	})

	const get_quadrant = ( position: NodePosition, detect_in_circle : boolean = false ) => {
		
		if ( position.x == width/2 && position.y == height/2 ) {
			return 0
		}
		
		// 2 | 1
		// -----
		// 3 | 4

		if ( detect_in_circle ) {
			// Outside circle
			let val = Math.pow(( position.x - width/2 ), 2) + Math.pow(( position.y - height/2 ), 2)
			if ( val > Math.pow( radius, 2 ) ) {
				return -1
			}
		}

		// 1st quadrant
		if ( position.x > width/2 && position.y >= height/2 ) {
			return 1
		}

		// 2nd quadrant
		if ( position.x <= width/2 && position.y > height/2 ) {
			return 2
		}

		// 3rd quadrant
		if ( position.x < width/2 && position.y <= height/2 ) {
			return 3
		}

		// 4th quadrant
		if ( position.x >= width/2 && position.y < height/2 ) {
			return 4
		}

	}

	const get_text_position = ( anchor: string, position : NodePosition, node : HierarchyPointNode<any>, is_description : boolean ) => {

		let text_origin = 5
		let pole = get_pole( position )
		// console.log(node.data.title.english)
		// console.log(pole)
		// console.log(anchor)

		let key = generate_id(node.data.title[props.language])
		let height = node_text_boxes.current[key]

		let offset = typeof height == "number" ? (16 * 1.1) : typeof height == "object" ? height.getBBox().height : 0

		switch (anchor) {
			case "middle":

				switch( pole ) {
					case CirclePole.north:

						return {
							...position,
							x : text_origin * Math.cos( position.angle.radians ) + position.x,
							y : is_description ? text_origin * Math.sin( position.angle.radians ) + position.y + offset / 2 : text_origin * Math.sin( position.angle.radians ) + position.y - 4,
						}

					case CirclePole.south:
						return {
							...position,
							x : text_origin * Math.cos( position.angle.radians ) + position.x,
							y : is_description ? text_origin * Math.sin( position.angle.radians ) + position.y + offset / 2 : text_origin * Math.sin( position.angle.radians ) + position.y,
						}
					case CirclePole.false:
						return {
							...position,
							x : text_origin * Math.cos( position.angle.radians ) + position.x,
							y : is_description ? text_origin+4 * Math.sin( position.angle.radians ) + position.y + offset /2: text_origin * Math.sin( position.angle.radians ) + position.y + 4,
						}
				}

			case "end": 
				return {
					...position,
					x : text_origin * Math.cos( position.angle.radians ) + position.x - 8, 
					y : text_origin * Math.sin( position.angle.radians ) + position.y - 16,
				}
			case "start":
				return {
					...position,
					x : text_origin * Math.cos( position.angle.radians ) + position.x + 10, 
					y : text_origin * Math.sin( position.angle.radians ) + position.y - 16,
				}
			default:
				return {
					...position,
					x : text_origin * Math.cos( position.angle.radians ) + position.x + 8, 
					y : text_origin * Math.sin( position.angle.radians ) + position.y - 8,
				}
		}

	}

	const get_line_width = ( anchor: string, position : NodePosition, node : HierarchyPointNode<any>, description: boolean = false) => {

		return props.line_width !== undefined ? width / props.line_width : props.radius !== undefined ? width / props.radius : 250

		//let pole = get_pole( position )

		// switch( pole ) {
		// 	case CirclePole.north:

		// 		//let key = generate_id(node.data.title[props.language])
		// 		//let height = node_text_boxes.current[key]

				

		// 		return 250

		// 	case CirclePole.south:
		// 		return 250
		// 	case CirclePole.false:
		// 		return 250
		// }

	}

	const get_pole = ( position : NodePosition ) : CirclePole => {

		let delta = 5 * Math.PI/180
		let angle = position.angle.radians

		if ( angle > 4.1887902047 && angle < 5.2359877559 ) {
			return CirclePole.south
		} else if ( angle > 1.0471975512 && angle < 2.0943951023 ) {
			return CirclePole.north
		}
	
		return CirclePole.false

	}

	const get_text_anchor = ( node: HierarchyPointNode<any>, position : NodePosition ):string => {

		let max_depth = props.levels !== undefined ? props.levels : 1

		if ( node.depth === max_depth ) {

			let pole = get_pole(position)

			switch(pole) {
				case CirclePole.north: case CirclePole.south:
					return "middle"
				default:

			}

			let quadrant = get_quadrant(position)

			switch( quadrant ) {
				// Right hand side
				case 1: case 4:
					return "start"
				// Left hand side
				case 2: case 3:
					return "end"
			}

		} 

		return "middle"

	}

	const get_description = ( node: HierarchyPointNode<ReifierModelInterface> ) => {
		
		let title = node.data.title[props.language]
		let key = generate_id(title)
		//console.log(key)

		let position = project(node)
		
		let text_anchor   = get_text_anchor( node, position )
		var text_position = get_text_position( text_anchor, position, node, true )
		let line_width    = get_line_width( text_anchor, position, node )

		let title_bounds = node_text_boxes.current[key]

		text_position = typeof title_bounds == "number" ? {
			...text_position,
			y : text_position.y + title_bounds + (16 * 1.2)
		} : typeof title_bounds == "object" ? {
			...text_position,
			y : text_position.y + 16
		} : {
			...text_position
		}

		return (
			<SVGText 
				key={ key+"description" }
				reference={ node_text_boxes }
				x={ text_position.x }
				y={ text_position.y }
				string={ node.data.description[props.language] }
				get_height={ get_text_height }
				maxLineWidth={ line_width }
				fontSize={ props.font_size !== undefined ? props.font_size : default_font_size }
				fontLeading={ 1.2 }
				fill={"#333"}
				textAnchor={ text_anchor }
				fontFamily={ "'Milo Slab OT Regular', Milo-Slab, sans-serif" }
				//rotation={ text_position.angle  }
			/>
		)

	}

	const get_container_transform = ():string|undefined => {
		
		return null
		console.log(container_dimensions)
		let x_shift = width-container_dimensions.width / 2
		console.log(x_shift)

		return "translate(-"+x_shift+" 0)"

	}

	const layout_nodes = () => {

		let line_generator = line()

		return nodes.map( ( node : HierarchyPointNode<any>, index : number ) => {

			// Project the position of the node
			let position = project(node)
			
			// Generate a key for the node
			let key = generate_id(node.data.title[props.language])
			
			// Generate the path for the line, skipping the first level.
			let path = node.depth > 1 ? line_generator( [ [position.parent_x-2, position.parent_y-2], [position.x-2, position.y-2] ] ) : undefined

			let text_anchor   = get_text_anchor( node, position )
			let text_position = get_text_position( text_anchor, position, node, false )
			let line_width    = get_line_width( text_anchor, position, node )

			return index !== 0 ? (
				<g id={key+"-group"} key={key+"-group"+index}>
					{ path !== undefined ? <path key={"path-"+key} d={ path } opacity={0.2} stroke="black" fill={"none"}/> : null }
					<g>
						<circle
							id   = { "circle-"+key }
							key  = { "circle-"+key }
							r    = { 4 }
							cx   = { position.x - 2 }
							cy   = { position.y - 2 }
							fill = "rgb(230, 60, 38)"
							//onClick={ (event:React.MouseEvent) => this.props.edit_node( event, title ) }
							style={ { "cursor" : "pointer" } }
						/>
						{ node.depth <= 4 ? <SVGText
							id={ key } 
							key={ key }
							reference={ node_text_boxes }
							x={ text_position.x }
							y={ text_position.y }
							string={ node.data.title[props.language] }
							get_height={ get_text_height }
							maxLineWidth={ line_width }
							fontSize={ props.font_size !== undefined ? props.font_size : default_font_size }
							fontLeading={ 1.2 }
							fill={"#333"}
							textAnchor={ text_anchor }
							fontFamily={ node.depth < 2 || node.data.components.length > 0 ? "'Milo Slab OT Bold', Milo-Slab, MiloSlabOT-Bold, 'Milo Slab OT', MiloSlabOT, sans-serif" : "Milo-Slab, MiloSlabOT-Light, 'Milo Slab OT Light', 'Milo Slab OT', MiloSlabOT, sans-serif" }
							fontWeight={ node.depth < 2 || node.data.components.length > 0 ? 500 : 300 }
							//rotation={ text_position.angle  }
						/> : null }
						{ node.data.description !== undefined && node.data.description[props.language] !== undefined && node.data.description[props.language] !== "" ? 
							get_description( node ) : null
						}
					</g>
				</g>
			) : null

		})

	}

	const title_style : React.CSSProperties = {
		display : "flex",
		flex : "row",
		textAlign : "center",
		justifyContent : "center",
		fontFamily : "'Milo Slab', Milo-Slab, san-serif",
		fontWeight : 100,
		marginBottom : "2%"
	}

	let model_title = props.json.title !== undefined ? props.json.title[props.language] : props.json.meta.title[props.language]

	return (
		<React.Fragment>
			<section ref={ div_element } id={generate_id(model_title)+"-model"} style={{ width : "100%" }}>
				<svg 
					ref={ svg_element }
					version="1.1" xmlns="http://www.w3.org/2000/svg" 
					xmlnsXlink="http://www.w3.org/1999/xlink" 
					style={ { width : "100%", height: "auto" } }
					viewBox={ viewBox } 
					>
					<g 
						id={ generate_id(model_title) } 
						key={ generate_id(model_title) }
						ref={ svg_container_group }
						transform={ get_container_transform() }
						>
						{ layout_nodes() }
					</g>
				</svg>
				<div style={title_style}>{model_title}</div>
			</section>
		</React.Fragment>
	)

}

export default ReifierModel