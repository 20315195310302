import * as React from 'react';
import { useRef, useState, useEffect } from 'react'
import { Language, LanguageSelector, LanguageContext } from '../language/Language'

import { gsap, Power1, CSSPlugin } from 'gsap'
gsap.registerPlugin(CSSPlugin)

import { hierarchy as Hierarchy, HierarchyNode, HierarchyPointNode, tree as Tree, line, curveBumpX, cluster as Cluster } from 'd3'

import { Simulation, forceSimulation as ForceSimulation, forceLink as ForceLink, forceManyBody as ForceManyBody, forceCenter as ForceCenter, forceX as ForceX, forceY as ForceY, forceCollide as ForceCollide } from "d3-force"

import { AdjacencyList, Graph, Vertex, VertexDictionary, GraphNode } from "../graph/Graph"

import { SVGText } from '../svg/svg-text'
import { generate_id } from './BlogHeaderLink'

enum CirclePole {
	north = "north",
	south = "south",
	false = "false"
}

export enum ModelType {
	list           = "list",
	linked_list    = "linked_list",
	cluster        = "cluster",
	radial_cluster = "radial_cluster",
	radial_tree    = "radial_tree",
	tree           = "tree",
	indented_tree  = "indented_tree",
	directed_graph = "directed_graph",
	tree_map       = "tree_map",
	partition      = "partition",
	pack           = "pack",
	force          = "force",
	venn           = "venn",
}

interface NodePosition {
    x: number;
    y: number;
    parent_x: number;
    parent_y: number;
    angle: number;
}

interface MultiLingualString {
	[key: string] : string
	english : string
	svenska : string
}

export interface TextDictionary {
	[index: string]: SVGTextElement
}

export interface SVGPathDictionary {
	[index: string]: SVGPathElement
}

export interface LineHeightDictionary {
	[index: string]: number
}

export interface CircleDictionary {
	[index: string]: SVGCircleElement
}

interface JanusNode extends GraphNode {
	id? : string
	title : string,
	description : string,
	comments : string,
	children : JanusNode[]
}

interface RadialHierarchyProps {
	language? : Language
	json : any
	rotation? : number
	levels? : number
}

interface RadialHierarchyState {
    width : number,
	height : number,
	viewBox : string,
}

export const ForceLayout = ( props:RadialHierarchyProps ) => {

	const div_element = useRef<HTMLDivElement>(null)
	const svg_element = useRef<SVGSVGElement>(null)

	const adjacencies = useRef<AdjacencyList<JanusNode>>()

	const node_circles           = useRef<CircleDictionary>({})
	const node_text_boxes        = useRef<TextDictionary>({})
	const node_description_boxes = useRef<TextDictionary>({})

	const link_lines             = useRef<SVGPathDictionary>({})

	const [fixed_top_level, setTopLevelFixed]    = useState<boolean>(false)
	const [inner_radius, setInnerRadius]         = useState<number>(0)
	const [outer_radius, setOuterRadius]         = useState<number>(0)
	const [radius, setRadius]                    = useState(0)
	const [show_description, setShowDescription] = useState<boolean>(true)
	const [line_width, setLineWidth]             = useState<number>(175)

	const [force_charge, setForceCharge]                    = useState<number>(2)
	const [force_collision_radius, setForceCollisionRadius] = useState<number>(75)
	const [force_mb_max, setForceMBMax]                     = useState<number>(0)
	const [force_link_distance, setForceLinkDistance]       = useState<number>(75)

	const [width, setWidth] = useState(0)
	const [height, setHeight] = useState(0)
	const [viewBox, setViewBox] = useState("0, 0, 0, 0")

	const [canvas_size, setCanvasSize] = useState({ width: 3840, height : 2160})

	const [nodes, setNodes] = useState<Vertex<JanusNode>[]>([])
	const [links, setLinks] = useState<VertexDictionary<JanusNode>>({})

	const [line_heights, set_line_heights] = useState<TextDictionary>({})

	let simulation : Simulation<any, any> = ForceSimulation()

	// Mount / unmount useEffect
	useEffect( () => {

		// Add event listener.
		window.addEventListener( 'resize', update_the_size )
		
		// Run update the size on first load
		update_the_size()

		return () => {
			window.removeEventListener('resize', update_the_size)
		}

	}, [])

	useEffect( () => {

		let adjacency_diagram = new AdjacencyList<JanusNode>()

		// Recursive function to create verticies and add them to the graph.
		const recurse_child = ( d:any, index:string ):JanusNode => {
			
			d.id = index

			// Convert the JSON Janus Node representation to a GraphNode compatible version
			d.title = props.language !== undefined && d.title[props.language] !== undefined ? d.title[props.language] : ""
			d.description = props.language !== undefined && d.definition[props.language] !== undefined ? d.definition[props.language] : ""
			d.children = d.components !== undefined ? Object.keys(d.components).map( (index) => recurse_child(d.components[index], index) ) : []

			delete d.components
			delete d.definition

			return d
		}

		// This could be populated with props.
		let parent_node : JanusNode = {
			title : "Force Node",
			description : "",
			comments : "",
			children : Object.keys(props.json).map( (index) => recurse_child(props.json[index], index))
		}
		
		adjacency_diagram.data = parent_node

		// Add links
		const link_child = ( d:JanusNode, parent:JanusNode|undefined ) => {

			let parent_vertex = parent !== undefined && parent.id !== undefined ? adjacency_diagram.get_vertex(parent.id) : parent !== undefined ? adjacency_diagram.create_vertex(parent) : undefined
			
			let child_vertex = d.id !== undefined && adjacency_diagram.get_vertex(d.id) !== undefined ? adjacency_diagram.get_vertex(d.id) : adjacency_diagram.create_vertex(d)

			parent_vertex !== undefined && child_vertex !== undefined ? adjacency_diagram.add_directed_edge(parent_vertex, child_vertex) : null

			Object.values(d.children).map( (element) => link_child(element, d))

		}

		Object.values(parent_node.children).map( (element) => link_child(element, undefined))

		console.log(adjacency_diagram.vertices)

		setNodes( adjacency_diagram.vertices )
		setLinks( adjacency_diagram.adjacencies )

		// Update the Ref to the adjacency
		adjacencies.current = adjacency_diagram

	}, [props.json, props.language] )

	useEffect( () => {
		console.log("Nodes Updated")

		if ( fixed_top_level ) {

			let tweaked_nodes = nodes.map( (node) => {

				if ( node.level === 0 ) {
					node.x = calculate_polar_x(node)
					node.y = calculate_polar_y(node)
				}

				return node

			})
			simulation.nodes( tweaked_nodes )
		} else {
			simulation.nodes( nodes )
		}

		// Do the links
		let links = adjacencies.current !== undefined ? Object.keys(adjacencies.current.adjacencies).flatMap( ( index:string ) => {

			return adjacencies.current.adjacencies[index].flatMap( ( child ) => {
				return {
					source: child.source,
					target: child.destination,
				}
			})

		}) : []

		simulation.force( "center", ForceCenter().x( canvas_size.width / 2 ).y( canvas_size.height / 2 ) )

		simulation.force( 'collision', ForceCollide().radius( force_collision_radius ) )

		// Apply a positive force
		simulation.force( "link", ForceLink().links( links ).distance( force_link_distance ) )

		// This iteration over the nodes, would also be a good place to specify any override, i.e. nodes that have fixed placement.

		simulation.restart()

	}, [nodes])

	simulation.on( 'tick', (): void => {

		simulation.nodes().length > 0 ? simulation.nodes().forEach( ( node, index ) => {

			// Check that the graph has been built
			if ( adjacencies.current === null ) {
				return
			}

			// let x_pos = Math.max(100, Math.min(1920 - 50, node.x))
			// let y_pos = Math.max(100, Math.min(1080 - 50, node.y))

			let key = node.data.id

			let circle_node = node_circles.current[key]
			let text_node   = node_text_boxes.current[key]

			let text_box_transform = getBBox( text_node, true, svg_element.current )
			
			// Animate the position of the nodes.
			if ( circle_node !== undefined ) { 

				//let circle_box_transform = getBBox( circle_node, true, svg_element )

				circle_node !== null ? gsap.set( circle_node, {
					// cx: node.level === 1 ? x_project : node.x,
					// cy: node.level === 1 ? y_project : node.y,
					attr: {
						cx: node.x,
						cy: node.y,
					}
				}) : null 

			}

			// Animate the position of the text.
			if ( text_node !== undefined ) {

				text_node !== null ? gsap.set( text_node, {
					x: node.x - text_box_transform.cx,
					y: node.y - text_box_transform.y + 8,
				}) : null
			}

			// Check if node has adjacencies.
			if ( links.hasOwnProperty( node.data.id ) ) {

				links[node.data.id].map( (edge, index) => {

					let id = edge.source.data.id+"_"+edge.destination.data.id
					let path_node  = link_lines.current[id]

					if ( path_node !== undefined ) {
						//let line = d3line()([ [ edge.source.x, edge.source.y ], [ edge.destination.x, edge.destination.y ] ] )
						let path = path_generator( [ [ edge.source.x, edge.source.y ], [ edge.destination.x, edge.destination.y ] ] )
						path_node != null ? gsap.set( path_node, { attr: { d: path } } ) : null 
					}

				})

			}

			// let description_node = node_description_boxes.current[get_id(node.data.description)]

			// // Set the position of the description node.
			// if ( index !== 0 && show_description && description_node !== undefined ) {

			// 	let description_box_transform = getBBox( description_node, true, svg_element.current )

			// 	description_node !== null ? gsap.set( description_node, {
			// 		// x: node.level === 1 ? x_project - text_box_transform.x : node.x - text_box_transform.x,
			// 		// y: node.level === 1 ? y_project - text_box_transform.y : node.y - text_box_transform.y,
			// 		x: node.x - description_box_transform.cx,
			// 		y: node.y - text_box_transform.y + text_box_transform.height - 16,
			// 		// textAnchor: get_text_align( x_project, width ),
			// 		// attr: {
			// 		// 	"text-anchor" : get_text_align( x_project, width )
			// 		// },
			// 	}) : null

			// }

		}) : null

	})

	const getBBox = ( element:SVGSVGElement|SVGTextElement, withTransforms:boolean = false, toElement:SVGSVGElement ) => {

		if (element == null) {
			return { x: 0, y: 0, cx: 0, cy: 0, width: 0, height: 0 };
		}

		var svg = element.ownerSVGElement;

		var r = element.getBBox();

		if ( withTransforms ) {
			return {
				x: r.x,
				y: r.y,
				width: r.width,
				height: r.height,
				cx: r.x + r.width / 2,	
				cy: r.y + r.height / 2
			}
		}

		var p = svg.createSVGPoint();

		var matrix = (toElement || svg).getScreenCTM().inverse().multiply( element.getScreenCTM() );

		p.x = r.x;
		p.y = r.y;
		var a = p.matrixTransform(matrix);

		p.x = r.x + r.width;
		p.y = r.y;
		var b = p.matrixTransform(matrix);

		p.x = r.x + r.width;
		p.y = r.y + r.height;
		var c = p.matrixTransform(matrix);

		p.x = r.x;
		p.y = r.y + r.height;
		var d = p.matrixTransform(matrix);

		var minX = Math.min(a.x, b.x, c.x, d.x);
		var maxX = Math.max(a.x, b.x, c.x, d.x);
		var minY = Math.min(a.y, b.y, c.y, d.y);
		var maxY = Math.max(a.y, b.y, c.y, d.y);

		var width = maxX - minX;
		var height = maxY - minY;

		return {
			x: minX,
			y: minY,
			width: width,
			height: height,
			cx: minX + width / 2,
			cy: minY + height / 2
		};
	}

	const update_the_size = ( () => {

		let width = div_element !== undefined && div_element.current !== null ? div_element.current.clientWidth : 0
		let height = width / 16 * 9

		setWidth( width )
		setHeight( height )
		setViewBox( "0 0 "+ canvas_size.width + " "+ canvas_size.height )
		setRadius( canvas_size.width/1.75 )
		setOuterRadius( canvas_size.width/8 )
		setInnerRadius( canvas_size.width/16 )

	} )

	const calculate_polar_x = ( ( element:Vertex<JanusNode> ) => {

		if ( adjacencies.current !== undefined && element.level == 0 ) {

			let nodes_at_this_level = adjacencies.current.siblings( element )

			// Get the index of this vertex in the siblings array.
			let this_index = nodes_at_this_level.indexOf( element )

			let angle = this_index / (nodes_at_this_level.length/2) * Math.PI - 1.5708;

			let radius = element.level === 0 ? outer_radius : canvas_size.width / 8

			// Calculate the x position of the element.
			var node_x = ( radius * Math.cos(angle) ) + canvas_size.width / 2 
			return node_x
		}

		return 0
	})

	const calculate_polar_y = ( ( element:Vertex<JanusNode> ) => {

		if ( adjacencies !== undefined && adjacencies.current !== undefined && element.level == 0 ) {

			let nodes_at_this_level = adjacencies.current.siblings(element)

			// Get the index of this vertex in the siblings array.
			let this_index = nodes_at_this_level.indexOf( element )

			let angle = this_index / ( nodes_at_this_level.length / 2 ) * Math.PI - 1.5708;

			let radius = element.level === 0 ? outer_radius : canvas_size.width / 8

			var node_y = ( radius * Math.sin( angle ) ) + canvas_size.height / 2
			return node_y

		}

		return 0
	})

	const project = ( node:HierarchyPointNode<any> ) => {

		let parent:NodePosition|null = node.depth > 1 && node.parent !== null ? project( node.parent ) : null

		let parent_x:number = parent !== null && node.depth > 1 ? parent.x : canvas_size.width / 2
		let parent_y:number = parent !== null && node.depth > 1 ? parent.y : canvas_size.height / 2

		let rotation = props.rotation !== undefined ? props.rotation : 0
		let angle = node.x / 180 * Math.PI - rotation
		let _radius = node.depth === 1 ? radius / 2.5 : radius

		return {
			x : _radius * Math.cos( angle ) + parent_x, 
			y : _radius * Math.sin( angle ) + parent_y,
			parent_x : parent_x,
			parent_y : parent_y,
			angle : angle
		}
	}

	const get_text_height = ( ( key:string, height:number ) => {


		if ( key === undefined || key === null || key === "" ) {
			return
		}

		node_text_boxes.current[key] = height

		set_line_heights(node_text_boxes.current)

	})

	const get_quadrant = ( position: NodePosition, detect_in_circle : boolean = false ) => {
		
		if ( position.x == canvas_size.width/2 && position.y == canvas_size.height/2 ) {
			return 0
		}
		
		// 2 | 1
		// -----
		// 3 | 4

		if ( detect_in_circle ) {
			// Outside circle
			let val = Math.pow(( position.x - canvas_size.width/2 ), 2) + Math.pow(( position.y - canvas_size.height/2 ), 2)
			if ( val > Math.pow( radius, 2 ) ) {
				return -1
			}
		}

		// 1st quadrant
		if ( position.x > canvas_size.width/2 && position.y >= canvas_size.height/2 ) {
			return 1
		}

		// 2nd quadrant
		if ( position.x <= canvas_size.width/2 && position.y > canvas_size.height/2 ) {
			return 2
		}

		// 3rd quadrant
		if ( position.x < canvas_size.width/2 && position.y <= canvas_size.height/2 ) {
			return 3
		}

		// 4th quadrant
		if ( position.x >= canvas_size.width/2 && position.y < canvas_size.height/2 ) {
			return 4
		}

	}

	const get_text_position = ( anchor: string, position : NodePosition, node : HierarchyPointNode<any> ) => {

		let text_origin = 10
		let pole = get_pole( position )

		switch (anchor) {
			case "middle":

				switch( pole ) {
					case CirclePole.north:

						let key = generate_id(node.data.id)
						let height = node_text_boxes.current[key]

						return {
							...position,
							x : text_origin * Math.cos( position.angle ) + position.x,
							y : height !== undefined ? text_origin * Math.sin( position.angle ) + position.y - 16 - 1080 : text_origin * Math.sin( position.angle ) + position.y - 16,
						}

					case CirclePole.south:
						return {
							...position,
							x : text_origin * Math.cos( position.angle ) + position.x,
							y : text_origin * Math.sin( position.angle ) + position.y + 4,
						}
					case CirclePole.false:
						return position
				}

			case "end": 
				return {
					...position,
					x : 10 * Math.cos( position.angle ) + position.x, 
					y : 10 * Math.sin( position.angle ) + position.y - 10,
				}
			case "start":
				return {
					...position,
					x : 10 * Math.cos( position.angle ) + position.x, 
					y : 10 * Math.sin( position.angle ) + position.y - 10,
				}
			default:
				return position
		}

	}

	const get_line_width = ( anchor: string, position : NodePosition, node : HierarchyPointNode<any> ) => {

		let pole = get_pole( position )

		switch( pole ) {
			case CirclePole.north:

				let key = generate_id(node.data.id)
				let height = node_text_boxes.current[key]

				return 50

			case CirclePole.south:
				return 50
			case CirclePole.false:
				return 200
		}

	}

	const get_pole = ( position : NodePosition ) : CirclePole => {

		let delta = 5 * Math.PI/180

		if ( position.angle > ( Math.PI / 3 ) && position.angle < ( ( 2 * Math.PI ) / 3 ) ) {
			return CirclePole.south
		// } else if ( position.angle > ( 4 * Math.PI / 3 ) && position.angle < ( 5 * Math.PI / 3 ) ) {
		// 	return CirclePole.north
		// }
		} else if ( position.angle > (((3 * Math.PI) / 2) - delta) && position.angle < ((( 3 * Math.PI / 2 ) + delta )) ) {
			return CirclePole.north
		}
	
		return CirclePole.false

	}

	const get_text_anchor = ( node: HierarchyPointNode<any>, position : NodePosition ):string => {

		let max_depth = props.levels !== undefined ? props.levels : 1

		if ( node.depth === max_depth ) {

			let pole = get_pole(position)

			switch(pole) {
				case CirclePole.north: case CirclePole.south:
					return "middle"
				default:

			}

			let quadrant = get_quadrant(position)

			switch( quadrant ) {
				// Right hand side
				case 1: case 4:
					return "start"
				// Left hand side
				case 2: case 3:
					return "end"
			}

		} 

		return "middle"

	}

	const line = ( pointA:any, pointB:any ) => {
		const lengthX = pointB[0] - pointA[0]
		const lengthY = pointB[1] - pointA[1]
		return {
			length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
			angle: Math.atan2(lengthY, lengthX)
		}
	}

	const controlPoint = ( current:any, previous:any, next:any, reverse?:boolean ) => {
		// When 'current' is the first or last point of the array
		// 'previous' or 'next' don't exist.
		// Replace with 'current'
		const p = previous || current
		const n = next || current
		// The smoothing ratio
		const smoothing = 0.2
		// Properties of the opposed-line
		const o = line(p, n)
		// If is end-control-point, add PI to the angle to go backward
		const angle = o.angle + (reverse ? Math.PI : 0)
		const length = o.length * smoothing
		// The control point position is relative to the current point
		const x = current[0] + Math.cos(angle) * length
		const y = current[1] + Math.sin(angle) * length
		return [x, y]
	}

	const bezierCommand = ( point:any, i:number, a:any ) => {
		// start control point
		const [cpsX, cpsY] = controlPoint(a[i - 1], a[i - 2], point)
		// end control point
		const [cpeX, cpeY] = controlPoint(point, a[i - 1], a[i + 1], true)
		return `C ${cpsX},${cpsY} ${cpeX},${cpeY} ${point[0]},${point[1]}`
	}

	const path_generator = ( ( points: any ) => {

		return points.reduce( (acc:any, point:any, i:number, a:Element) => i === 0
			// if first point
			? `M ${point[0]},${point[1]}`
			// else
			: `${acc} ${ bezierCommand(point, i, a) }`
		, '')

	})

	const svg_path = ( ( id:string, points:any, command:any, ref? : (el: SVGPathElement) => void ) => {
		// build the d attributes by looping over the points
		const d = path_generator(points)

		return <path id={id} key={id} ref={ ref } d={ d } fill="none" stroke="grey" opacity={0.25} />
	})

	const render_the_nodes = ( ( node : Vertex<JanusNode>, index : number ) => {
		// Parse the title.
		let title = node.data !== undefined ? node.data.title : "node-title"
		let id    = node.data.id

		let paths = id !== undefined && links.hasOwnProperty( id ) ? links[id].map( edge => svg_path( edge.source.data.id+"_"+edge.destination.data.id, [ [ edge.source.x, edge.source.y ], [ edge.destination.x, edge.destination.y ] ], 'bezierCommand', ( (el) => { link_lines.current[edge.source.data.id+"_"+edge.destination.data.id] = el } )) ): []

		let font_size = node.level === 0 ? 16 : 14	

		return (
			<g key={ index }>
				{ paths }
				<circle
					id   = { "circle_"+id }
					ref  = { el => id !== undefined && el !== null ? node_circles.current[id] = el : null }
					key  = { title + index }
					r    = { 5 }
					cx   = { canvas_size.width / 2 }
					cy   = { canvas_size.height / 2 }
					fill = { "rgb(230, 60, 38)" }
					//onClick={ (event:React.MouseEvent) => this.props.edit_node( event, title ) }
					style={ { "cursor" : "pointer" } }
				/>
				<SVGText
					id={ id } 
					key={ "title_"+index }
					reference = { node_text_boxes }
					x = { canvas_size.width / 2 }
					y = { canvas_size.height / 2 }
					maxLineWidth = { line_width }
					fontSize = { font_size }
					fill = { "#333" }
					textAnchor = { "middle" }
					fontFamily = { node.data.children.length === 0 ? "'Milo Slab OT Regular', MiloSlabOT-Regular" : "'Milo Slab OT Bold', MiloSlabOT-Bold" }
					fontWeight= { node.data.children !== undefined && node.data.children.length > 0 ? 500 : 300 }
					string = { title }
					get_height = { get_text_height }
				/>
				{ show_description === true ? (
					<SVGText
						key = { "description_"+index }
						reference = { node_description_boxes }
						x = { canvas_size.width / 2 }
						y = { canvas_size.height / 2 }
						fontFamily = { "'Milo Slab OT Regular', Milo-Slab, sans-serif" }
						fontWeight = { 400 }
						fontSize = { 14 }
						fill = { "#333" }
						textAnchor = { "middle" }
						maxLineWidth = { line_width * 1.5 }
						string = { node.data !== undefined ? node.data.description : "" }
						get_height = { get_text_height }
						opacity = { 1.0 }
					/>
				) : null }
			</g>
		)

	})

	return (
		<React.Fragment>
			<section ref={ div_element } id="radial-hierarchy" style={{ width : "100%" }}>
				<div style={{ width : width, height: height, overflowX : "scroll", overflowY : "scroll" }}>
				<svg 
					ref={ svg_element }
					version="1.1" xmlns="http://www.w3.org/2000/svg" 
					xmlnsXlink="http://www.w3.org/1999/xlink" 
					style={ { width : canvas_size.width, height: canvas_size.height } }
					viewBox={ viewBox } 
					>
					<g id="icf-rehab-set" key={"icf-rehab-set"}>
						{ nodes !== null ? nodes.flatMap( render_the_nodes ) : null }
					</g>
				</svg>
				</div>
			</section>
		</React.Fragment>
	)

}

export default ForceLayout