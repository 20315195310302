export enum EdgeType {
	directed = "directed",
	undirected = "undirected"
}

export interface GraphNode {
	id? : (number|string),
	title : string,
	description? : string,
	children? : GraphNode[],
}

export interface VertexDictionary<Element extends GraphNode> {
	[index: string]: Edge<Element>[]
}

export class Vertex<Element extends GraphNode> {
	
	index : number
	level : number
	data : Element
	x? : number
	y? : number
	fx? : number
	fy? : number

	constructor( index: number, data: Element, level?: number) {
		this.index = index;
		this.level = level !== undefined ? level : 0
		this.data = data;
	}

	description : () => string

	children : Vertex<Element>[]
}

export class Edge<Element extends GraphNode> {

	source: Vertex<Element>
	destination: Vertex<Element>
	weight?: number
	label?: string

	constructor( source: Vertex<Element>, destination: Vertex<Element>, weight?: number, label?: string ) {
		this.source = source
		this.destination = destination
		weight !== undefined ? this.weight = weight : null
		label !== undefined ? this.label = label : null
	}

}

export interface GraphInterface<Element extends GraphNode> {

	vertices            : Vertex<Element>[]

	create_vertex       : ( data: Element ) => Vertex<Element>
	
	add_directed_edge   : ( from : Vertex<Element>, to : Vertex<Element>, weight? : number ) => void
	add_undirected_edge : ( between : Vertex<Element>, and : Vertex<Element>, weight? : number ) => void
	add                 : ( edge : EdgeType, from : Vertex<Element>, to : Vertex<Element>, weight? : number ) => void

	edges               : ( from : Vertex<Element> ) => Edge<Element>[]
	weight              : ( from : Vertex<Element>, to : Vertex<Element> ) => number

}

export interface QueueInterface<Element> {
	enqueue  : ( element:Element ) => boolean
	dequeue  : () => Element|boolean
	is_empty : () => boolean
	peek     : () => Element|undefined
}

export class Queue<Element> implements QueueInterface<Element> {

	count : number = 0

	right_stack : Element[] = []
	left_stack : Element[] = []

	is_empty = () => ( this.right_stack.length === 0 && this.left_stack.length === 0 )

	peek = () => this.left_stack.length > 0 ? this.left_stack.slice(-1).pop() : [...this.right_stack].shift()  

	enqueue = ( ( element: Element ) : boolean => {
		this.count += 1
		this.right_stack.push( element )
		return true
	})

	dequeue = ( () : Element|false => {

		this.left_stack.length === 0 ? (this.left_stack = this.right_stack.reverse(), this.right_stack = []) : null

		let value = this.left_stack.pop()

		value !== undefined ? this.count -= 1 : null

		return value !== undefined ? value : false
	})

	elements = ( () => {
		return this.right_stack
	})

}

export interface StackInterface<Element> {
	
	storage  : Element[]
	push     : (element : Element) => void
	pop      : () => Element|undefined
	peek     : () => Element|undefined
	is_empty : () => boolean
	elements : () => Element[]
	count    : () => number

}

export class Stack<Element> implements StackInterface<Element> {

	storage : Element[] = []

	load( elements:Element[] ) {
		this.storage = elements
	}

	push = (element : Element ) => {
		this.storage.push(element)
	}

	pop = () => {
		return this.storage.pop()
	}

	peek = () => {
		return this.storage.length > 0 ? this.storage.slice(-1).pop() : undefined
	}

	is_empty = () => {
		return this.peek() === undefined
	}

	elements = () => {
		return this.storage
	}

	count = () => {
		return this.storage.length
	}

}

export class Graph<Element extends GraphNode> implements GraphInterface<Element> {

	adjacencies : VertexDictionary<Element> = {} as VertexDictionary<Element>
	vertices    : Vertex<Element>[] = []

	create_vertex = ( ( data: Element, level:number = 0 ):Vertex<Element> => {

		let vertex = new Vertex<Element>( this.vertices.length, data as Element, level )
		this.vertices.push(vertex)

		data.id !== undefined ? this.adjacencies[data.id] = [] : this.adjacencies[data.title] = []

		return vertex

	})

	remove_vertex = ( ( vertex: Vertex<Element>, delete_children : boolean = false ):boolean => {

		// Remove the verticies.
		this.vertices = this.vertices.filter( item => item !== vertex)

		// Get the index of this vertice.
		let from_index = vertex.data.id !== undefined ? vertex.data.id : vertex.data.title

		// If delete children is selected.
		if ( delete_children && this.adjacencies[from_index] !== undefined && this.adjacencies[from_index].length > 0 ) {

			this.adjacencies[from_index].forEach( ( edge, index ) => {
				
				this.remove_vertex(edge.destination, delete_children)

			})

		}

		// Remove adjacencies.
		delete this.adjacencies[from_index]

		return true

	})

	add_directed_edge = ( ( from: Vertex<Element>, to: Vertex<Element>, weight?: number, label?: string ) => {

		if ( from === undefined ) {
			console.log("Can't create directed edge, from vertex is undefined."+ this.vertices[0].data.title+"("+this.vertices[0].data.id+")")
			return
		}

		if ( to === undefined ) {
			console.log("Can't create directed edge, to vertex is undefined in " + this.vertices[0].data.title+"("+this.vertices[0].data.id+")")
			return
		}

		// Create the Edge.
		let edge = new Edge(from, to, weight)

		// Get the index of the vertex
		let from_index = from.data.id !== undefined ? from.data.id : from.data.title

		// Add the edge to the adjacencies dictionary.
		this.adjacencies[from_index] !== undefined ? this.adjacencies[from_index].push(edge) : this.adjacencies[from_index] = [edge]

	})

	add_undirected_edge = ( ( between: Vertex<Element>, and: Vertex<Element>, weight?: number, label?: string ):void => {  

		this.add_directed_edge(between, and, weight, label)
		this.add_directed_edge(and, between, weight, label)

	})

	add = ( ( edge: EdgeType, from: Vertex<Element>, to: Vertex<Element>, weight?: number, label?: string ):void => {

		switch( edge ) {
			case EdgeType.directed: 
				this.add_directed_edge(from, to, weight, label)
			break
			case EdgeType.undirected:
				this.add_undirected_edge(from, to, weight, label)
			break
		}

	})

	get_vertex = ( id:(string|number) ):Vertex<Element>|undefined => {

		// Value will be the first (and only) index of the passed in array.
		return this.vertices.find( vertex => vertex.data.id !== undefined ? vertex.data.id === id : vertex.data.title !== undefined ? vertex.data.title === id : false )

	}

	siblings = ( ( from: Vertex<Element> ): Vertex<Element>[] => {
		let from_index = from.data.id !== undefined ? from.data.id : from.data.title
		
		for ( let adjacency_list of Object.values(this.adjacencies) ) {
			if ( adjacency_list.find( (value) => value.destination === from ) ) {
				return adjacency_list.map( element => element.destination )
			}
		}

		return []

	})

	edges = ( ( from: Vertex<Element> = this.vertices[0] ): Edge<Element>[] => {
		let from_index = from.data.id !== undefined ? from.data.id : from.data.title
		return this.adjacencies[from_index] ?? []
	})

	weight = ( ( from: Vertex<Element>, to: Vertex<Element> ):number => {

		let edge = this.edges( from ).find( (element) => element.destination !== undefined ? element.destination === to : false)
		
		if ( edge !== undefined && edge.weight !== undefined ) {
			return edge.weight
		}

		return 0
	})

	is_disconnected = ( () => {  

		// If the graph is empty, it is connected.
		if ( this.vertices.length > 0 ) {
			return false
		}

		let visited = this.breadth_first_search( this.vertices[0] )

		visited.forEach( (element) => {
			if ( !visited.includes(element) ) {
				return true
			}
		})

		return false

	})

	breadth_first_search = ( from: Vertex<Element> = this.vertices[0] ):Vertex<Element>[] => {

		// initiate the BFS algorithm by first enqueuing the source vertex.
		let queue : Queue<Vertex<Element>> = new Queue<Vertex<Element>>()

		// enqueued remembers which vertices have been enqueued before so you don’t enqueue the same vertex twice. You use a Set type here so that lookup is cheap and only takes O(1).
		let enqueued : Set<Vertex<Element>>  = new Set<Vertex<Element>>()

		// visited is an array that stores the order in which the vertices were explored.
		let visited : Array<Vertex<Element>> = []

		queue.enqueue(from)
		enqueued.add(from)

		for ( var vertex : false | Vertex<Element>; vertex = queue.dequeue(); ) {
			
			if ( vertex ) {
				visited.push(vertex)

				let neighbour_edges = this.edges(vertex)
				neighbour_edges.forEach( (edge) => {
					
					if ( !enqueued.has(edge.destination) ) {
						queue.enqueue(edge.destination)
						enqueued.add(edge.destination)
					}

				})
			}

		}
		
		return visited

	}

	depth_first_search = ( from: Vertex<Element> = this.vertices[0] ):Vertex<Element>[] => {

		// stack is used to store the path through the graph.
		let stack : Stack<Vertex<Element>> = new Stack<Vertex<Element>>()
		// pushed remembers which vertices have been pushed before so that you don’t visit the same vertex twice. It is a Set to ensure fast O(1) lookup.
		let pushed : Set<Vertex<Element>>  = new Set<Vertex<Element>>()
		// visited is an array that stores the order in which the vertices were visited.
		let visited : Array<Vertex<Element>> = []
		
		// Insert the starting node.
		stack.push(from)
		pushed.add(from)
		visited.push(from)

		outer: for ( var vertex : false | undefined | Vertex<Element>; vertex = stack.peek(); ) {
			if ( vertex ) {

				let neighbour_edges = this.edges(vertex)

				if ( neighbour_edges.length === 0 ) {
					stack.pop()
					continue outer
				}

				for ( var edge of neighbour_edges ) {
					if ( !pushed.has(edge.destination) ) {
						stack.push(edge.destination)
						pushed.add(edge.destination)
						visited.push(edge.destination)
						continue outer
					}
				}

				stack.pop()

			}
		}

		return visited

	}

	// A graph is said to have a cycle when there is a path of edges and vertices leading back to the same source. This is only valid therefore for a directed graph.
	has_cycle = ( ( from : Vertex<Element> ):boolean => {

		let pushed : Set<Vertex<Element>>  = new Set<Vertex<Element>>()
		return this.has_cycle_helper(from, pushed)

	})

	private has_cycle_helper = ( ( from : Vertex<Element>, pushed : Set<Vertex<Element>> ):boolean => {

		pushed.add(from)

		let neighbour_edges = this.edges(from)

		for ( const edge of neighbour_edges ) {
			if ( !pushed.has(edge.destination) && this.has_cycle_helper(edge.destination, pushed) ) {
				return true
			} else if ( pushed.has(edge.destination) ) {
				return true
			}
		}

		pushed.delete(from)
		return false

	})

	private parse_function = ( (vertice:Vertex<Element>) => {

		// TODO: Don't know how this will work with D3, possibly will want to filter by level?

		// OR ditch this completely, and return just first level siblings?

		return vertice.data !== undefined ? {
			...vertice,
			...vertice.data
		} : {
			...vertice,
		}
	})

	bfs_hierarchy:()=>Vertex<Element>[] = () => this.breadth_first_search().map( this.parse_function )

	dfs_hierarchy:()=>Vertex<Element>[] = () => this.depth_first_search().map( this.parse_function )

}

export class AdjacencyList<Element extends GraphNode> extends Graph<Element> {
	
	data? : Element

	description = ( () => {
		
		var description_string = ""
		
		Object.keys( this.adjacencies ).forEach( ( key:string ) => {
			let edge_string = ""
			let elements = this.adjacencies[key]

			elements.forEach( ( edge, index ) => {
				
				if ( index != elements.length -1 ) {
					if ( edge.destination !== undefined && edge.destination.data.id !== undefined ) {
						edge_string += edge.destination.data.title + " ("+ edge.destination.data.id +"), " 
					} else {
						edge_string += edge.destination.data.title
					}
				} else {
					if ( edge.destination !== undefined && edge.destination.data.id !== undefined ) {
						edge_string += edge.destination.data.title + " ("+ edge.destination.data.id +")" 
					} else {
						edge_string += edge.destination.data.title
					} 
				}
			})

			let vertice = this.vertices.find( (vertex) => vertex.data.id !== undefined ? String(vertex.data.id) === key : vertex.data.title !== undefined ? String(vertex.data.title) === key : false )

			vertice !== undefined ? vertice.data.id !== undefined ? description_string += ( vertice.data.title + " (" + key + ") ---> [ " + edge_string + " ]\n") : description_string += ( " " + vertice.data.title + " ---> [ " + edge_string + " ]\n" ) : null
		
		})

		return description_string

	})

	links = ():Edge<Element>[] => {
		let links = new Set<Edge<Element>>()

		Object.keys( this.adjacencies ).forEach( ( key:string ) => {
		let elements = this.adjacencies[key]

			elements.forEach( ( edge, index ) => {
				links.add(edge)
			})
		})

		return Array.from(links)
	}

	hierarchy = () => {

		let level = 0

		let parent_func = ( edge:Edge<Element> ):Element|false => {
			level += 1
			let child_nodes = edge.destination !== undefined ? this.edges( edge.destination ) : []

			let element = edge.destination !== undefined ? {
				...edge.destination.data,
				parents : edge.source.data.id !== undefined ? [edge.source.data.id] : [edge.source.data.title],
				children : child_nodes.map( parent_func ).filter( child => child !== false ),
				level : level
			} : false

			return element
		}

		// We need to strip out before we get to this pass.

		if ( this.data !== undefined && this.data.id !== undefined ) {

			let model_node = this.get_vertex( this.data.id )

			let child_nodes = this.edges( model_node )

			// TODO: Figure out how to make this generic, whilst still offering the recursion required to return a hierarchy.

			let data = this.data !== undefined ? {
				id : this.data.id,
				title : this.data.title,
				children : this.data.children !== undefined ? child_nodes.map( parent_func ).filter( child => child !== false ) : [],
				level : level
			} : undefined

			return data

		}

	}

	remove_vertex = ( ( vertex: Vertex<Element>, delete_children : boolean = false ):boolean => {

		// Remove the verticies.
		this.vertices = this.vertices.filter( item => item !== vertex )

		// Get the index of this vertice.
		let from_index = vertex.data.id !== undefined ? vertex.data.id : vertex.data.title

		// If delete children is selected.
		if ( delete_children && this.adjacencies[from_index] !== undefined && this.adjacencies[from_index].length > 0 ) {

			this.adjacencies[from_index].forEach( ( edge, index ) => {

				this.remove_vertex(edge.destination, delete_children)

			})

		}

		// Remove any parental relations.
		this.remove_parent_relations(vertex)

		// Remove adjacencies.
		delete this.adjacencies[from_index]

		this.data !== undefined ? this.data.children = this.vertices.map( vertex => vertex.data ) : null

		return true

	})

	is_top_level = ( vertex : Vertex<Element> ):(Edge<Element>|false) => {

		let model_node = this.vertices[0]

		if ( vertex.data === undefined ) {
			return false
		}

		let vertex_index = vertex.data.id !== undefined ? vertex.data.id : vertex.data.title

		for ( const key of Object.keys( this.adjacencies ) ) {
			for ( const edge of this.adjacencies[key] ) {

				if ( ( ( edge.destination.data.id === vertex_index || edge.destination.data.title === vertex_index ) && edge.source.data.id === model_node.data.id ) ) {
					return edge
				} else if ( ( edge.destination.data.id === model_node.data.id && ( edge.source.data.id === vertex_index || edge.source.data.title === vertex_index ) ) ) {
					return edge
				}

			}
		}

		return false

	}

	parent_relations = ( from_index: string|number ) => {

		let links = new Set<Edge<Element>>()

		Object.keys( this.adjacencies ).forEach( ( key:string ) => {
			this.adjacencies[key].forEach( ( edge, index ) => {
				
				edge.destination.data.id !== undefined && edge.destination.data.id === from_index ? links.add(edge) : edge.destination.data.title === from_index ? links.add(edge) : null
			})
		})

		return Array.from(links)

	}

	child_relations = ( from: Vertex<Element> ) => {

		let links = new Set<Edge<Element>>()
		let from_index = from.data.id !== undefined ? from.data.id : from.data.title

		Object.keys( this.adjacencies ).forEach( ( key:string ) => {
			this.adjacencies[key].forEach( ( edge, index ) => {

				edge.destination.data.id !== undefined && edge.source.data.id === from_index ? links.add(edge) : edge.source.data.title === from_index ? links.add(edge) : null
			})
		})

		return Array.from(links)

	}

	remove_edge = ( remove: Edge<Element> ) => {

		Object.keys( this.adjacencies ).map( ( index:string ) => {

			// Filter out the selected index.
			this.adjacencies[index] = this.adjacencies[index].filter( edge => edge !== remove)

			// If there are no children at this index, then delete the index.
			if ( this.adjacencies[index].length === 0 ) {
				delete this.adjacencies[index]
			}

		})

	}

	add_parent_relation = ( to: Vertex<Element> ) => {

		this.add_directed_edge( this.vertices[0], to )

	}

	// Removes all existing reltions involving a vertex
	remove_parent_relations = ( from: Vertex<Element> ) => {

		let from_index = from.data.id !== undefined ? from.data.id : from.data.title

		Object.keys( this.adjacencies ).map( ( index:string ) => {
			
			this.adjacencies[index] = this.adjacencies[index].filter( edge => {
				// This filters out all relations where parent is model id, and destination is given id.
				return edge.destination.data.id !== undefined ? edge.destination.data.id !== from_index && edge.source.data.id === this.vertices[0].data.id : edge.destination.data.title !== from_index && edge.source.data.id === this.vertices[0].data.id 
			
			})
			
		})

	}

}