import React, { useRef, useState, useEffect } from 'react'
import { gsap, Power1, CSSPlugin } from 'gsap'
gsap.registerPlugin(CSSPlugin)

type SVGElements = (SVGShape|SVGGroup)

export interface SVGCanvas {
	id:         string;
    groups:     SVGGroup[];
	shapes:     any[];
    height:     number;
    width:      number;
    originY:    number;
    originX:    number;
    canvasSize: number[];
}

export interface SVGGroup {
    groups:         SVGGroup[];
    shapes:         SVGShape[];
    isClippingPath: boolean;
    id:             string;
    title:          string;
    shapeType:      SVGShapeType;
    fillRule:       FillRule;
    transform:      number[];
}

export enum FillRule {
    nonzero = "nonzero",
}

export interface ShapeElement {
    groups?:        any[];
    shapes?:        ShapeElement[];
    isClippingPath: boolean;
    id:             string;
    title:          string;
    shapeType:      SVGShapeType;
    fillRule:       FillRule;
    transform:      number[];
    data?:          string;
    fill?:          string;
}

export enum SVGShapeType {
    group    = "group",
    path     = "path",
	rect     = "rect",
	line     = "line",
	polygon  = "polygon",
	polyline = "polyline",
	text     = "text",
	circle   = "circle"
}

export interface SVGPoint {
	x: number,
	y: number
}

export interface SVGShape {
    cx:             number
    isClippingPath: boolean
    id:             string
    cy:             number
    title:          string
    shapeType:      SVGShapeType
    fill:           string
    fillRule:       FillRule
    rx:             number
    transform:      number[]
    ry:             number
	stroke:         string
	strokeWidth:    number
	opacity:        number
}

export interface SVGRect extends SVGShape {
	x1:     number
	y1:     number
	width:  number
	height: number
}

export interface SVGLine extends SVGShape {
	x1: number
	y1: number
	x2: number
	y2: number
}

export interface SVGPath extends SVGShape {
	data: string,
	//instructions : SVGPathInstruction[]
	//subpaths : SVGSubPath[]
}

export interface SVGPolyline extends SVGShape {
	points : SVGPoint[]
}

export interface SVGPolygon extends SVGShape {
	points : SVGPoint[]
}

export interface SVGText extends SVGShape {
	value : string
	tspans : SVGText[]
	fontFamily : string,
	fontSize : number,
	textAnchor : string,
}

export interface SVGTSpan extends SVGText {

}

interface SVGDimension {
	width : number,
	height : number
}

interface SVGComponentProps {
	id : string,
	svg : SVGCanvas
}

interface SVGComponentState {
    width : number,
	height : number,
	viewBox : string,
}

export const SVGComponent = ( props:SVGComponentProps ) => {

	const section_element     = useRef<HTMLDivElement>(null)
	const svg_element         = useRef<SVGSVGElement>(null)
	const svg_container_group = useRef<SVGGElement>(null)

	const [svg_orig_dimensions, set_svg_orig_dimensions] = useState<SVGDimension>({ width: 0, height: 0 })

	const [width, setWidth]     = useState(0)
	const [height, setHeight]   = useState(0)
	const [viewBox, setViewBox] = useState("0, 0, 0, 0")

	// Mount / unmount useEffect
	useEffect( () => {

		// Add event listener.
		window.addEventListener( 'resize', update_the_size )
		
		// Run update the size on first load
		update_the_size()
	
	}, [])

	useEffect( () => {

		svg_transform()

	}, [width] )

	useEffect( () => {

		setViewBox( "0 0 "+ width + " "+ height )

	}, [height] )

	const update_the_size = ( () => {

		let width = section_element !== undefined && section_element.current !== null ? section_element.current.clientWidth : 0
		let height = (width / 4 * 3)

		setWidth( width )
		setHeight( height )
		setViewBox( "0 0 "+ width + " "+ height )

	} )

	const get_computed_dimensions = ( ( element:HTMLDivElement ) => {

		var cs = getComputedStyle(element);

		var paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
		var paddingY = parseFloat(cs.paddingTop) + parseFloat(cs.paddingBottom);

		var marginX = parseFloat(cs.marginLeft) + parseFloat(cs.marginRight);
		var marginY = parseFloat(cs.marginTop) + parseFloat(cs.marginBottom);

		var borderX = parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth);
		var borderY = parseFloat(cs.borderTopWidth) + parseFloat(cs.borderBottomWidth);

		// Element width and height minus padding and border
		let elementWidth = element.offsetWidth
		let elementHeight = element.offsetHeight

		return {
			width : elementWidth,
			height : elementHeight
		}

	})

	const svg_transform = ( () => {

		let tl = gsap.timeline()

		let section_width = section_element.current != undefined ? get_computed_dimensions(section_element.current).width : 0
		let section_height = section_element.current != undefined ? get_computed_dimensions(section_element.current).height : 0

		// console.log("Section Width: "+section_width)
		// console.log("Section Height: "+section_height)

		let orig_svg_width = svg_container_group.current !== null ? svg_container_group.current.getBBox().width : svg_orig_dimensions.width !== 0 ? svg_orig_dimensions.width : section_width
		let orig_svg_height = svg_container_group.current !== null ? svg_container_group.current.getBBox().height : svg_orig_dimensions.height !== 0 ? svg_orig_dimensions.height : section_height

		// console.log("Original SVG Width: "+orig_svg_width)
		// console.log("Original SVG Height: "+orig_svg_height)

		let height = section_width / 4 * 3

		// section_width > height ? console.log("width is greater than height") : console.log("height is greater than width")

		let scale_x = orig_svg_width >= section_width ? section_width / orig_svg_width : orig_svg_width / section_width

		//console.log(scale_x)

		let scale_y = orig_svg_height >= height ? height / orig_svg_height : orig_svg_height / height

		//console.log(scale_y)

		let scale = section_width >= height ? scale_x : scale_y

		//console.log(scale)

		let diff_x = section_width - (orig_svg_width * scale)
		let diff_y = height - (orig_svg_height * scale)

		// console.log(diff_x)
		// console.log(diff_x)

		tl.set(svg_container_group.current, {
			scale: scale,
			x: diff_x / 2,
			y: diff_y / 2,
			svgOrigin: `0% 0%`,
		})

		// let strings = svgTransformStrings

		// if ( return_string != "" ) {
		// 	strings[key] = return_string 
		// }

		var dimensions = svg_orig_dimensions

		if ( dimensions === undefined ) {
			dimensions = {
				width : orig_svg_width,
				height : orig_svg_height,
			}
		}

		tl.to(svg_container_group.current, {
			opacity : 1,
		})

		//setSvgTransformStrings(strings)
		set_svg_orig_dimensions(dimensions)

	})

	const generate_transform = ( ( svg_shape : any ):string|undefined => {

		var return_string = ""

		// Translate
		if ( svg_shape.translate_x != null ) {
			return_string += "translate("+svg_shape.translate_x+" "
		} else {
			return_string += "translate(0 "
		}

		if ( svg_shape.translate_y != null ) {
			return_string += svg_shape.translate_y+")"
		} else {
			return_string += "0)"
		}

		// Rotate
		if ( svg_shape.rotate_angle != null) {
			return_string += " rotate("+svg_shape.rotate_angle

			if ( svg_shape.rotate_origin_x != null && svg_shape.rotate_origin_y != null ) {
				return_string += " "+svg_shape.rotate_origin_x+" "+svg_shape.rotate_origin_y+")" 
			} else if (return_string != "" ){
				return_string += ")"
			}
		}

		// Scale
		if ( svg_shape.scale_x != null && svg_shape.scale_y != null ) {
			return_string += " scale("+svg_shape.scale_x+" "+svg_shape.scale_y+")" 
		}

		// SkewX
		if ( svg_shape.skewX != null) {
			return_string += " skewX("+svg_shape.skewX+")"
		}

		// SkewY
		if ( svg_shape.skewY != null) {
			return_string += " skewY("+svg_shape.skewY+")"
		}

		return return_string != "" ? return_string : undefined
	})

	const render_svg_tspan = ( ( tspan : any, index : number ) => {
		//console.log(tspan)
		if ( tspan.x != null && tspan.y != null ) {
			return <tspan key={index} x={tspan.x} y={tspan.y}>{tspan.value}</tspan>
		}
	})

	const render_svg_group = ( ( svg_group : SVGGroup, index : number ) => {

		let groups = svg_group.groups != null ? svg_group.groups.map( (group, index) => render_svg_group( group, index ) ) : null
		let shapes = svg_group.shapes != null ? svg_group.shapes.map( (shape, index) => render_svg_shape( shape, index ) ) : null

		// Merge the arrays if not null.
		let elements = [...groups||[], ...shapes||[]]

		return (
			<g key={svg_group.id+index} id={svg_group.title}>
				{ elements }
			</g>
		)
	})

	const render_svg_shape = ( ( svg_shape : SVGShape, index : number ) => {

		switch( svg_shape.shapeType ) {
			case "rect":

				let svg_rect = svg_shape as SVGRect

				return <rect key={svg_rect.id} id={ svg_rect.title } x={ svg_rect.x1 } y={ svg_rect.y1 } width={ svg_rect.width } height={ svg_rect.height } fill={ svg_rect.fill } rx={ svg_rect.rx } ry={ svg_rect.ry } stroke={ svg_rect.stroke } strokeWidth={ svg_rect.strokeWidth } transform={ generate_transform(svg_rect) } opacity={svg_rect.opacity}/>

			case "line":
				
				let svg_line = svg_shape as SVGLine

				return <line key={svg_line.id} id={ svg_line.title } x1={ svg_line.x1 } y1={ svg_line.y1 } x2={ svg_line.x2 } y2={ svg_line.y2 } fill={ svg_line.fill } stroke={ svg_line.stroke } strokeWidth={ svg_line.strokeWidth } transform={ generate_transform(svg_line) }  opacity={svg_line.opacity}/>

			case "path":

				let svg_path = svg_shape as SVGPath

				return <path key={svg_path.id} id={ svg_path.title } d={ svg_path.data } fill={ svg_path.fill } stroke={ svg_path.stroke } opacity={ svg_path.opacity } transform={ generate_transform(svg_path) } strokeWidth={ svg_path.strokeWidth } />
			case "polyline":

				let svg_polyline = svg_shape as SVGPolyline

				return <polyline key={svg_polyline.id} id={ svg_polyline.title } points={ svg_polyline.points.map( point => { return [point.x, point.y] } ).toString() } fill={ svg_polyline.fill } stroke={ svg_polyline.stroke } transform={ generate_transform(svg_polyline) } strokeWidth={ svg_polyline.strokeWidth } opacity={svg_polyline.opacity}/>
			case "polygon":

				let svg_polygon = svg_shape as SVGPolygon

				return <polygon key={svg_polygon.id} id={ svg_polygon.title } points={ svg_polygon.points.map( point => { return [point.x, point.y] } ).toString() } fill={ svg_polygon.fill } stroke={ svg_polygon.stroke } transform={ generate_transform(svg_polygon) } strokeWidth={ svg_polygon.strokeWidth } opacity={svg_polygon.opacity}/>
			case "text":

				let svg_text = svg_shape as SVGText

				return <text key={svg_text.id} id={ svg_text.title } transform={ generate_transform(svg_text) } fontFamily={ svg_text.fontFamily+", Open Sans, san-serif" } fontSize={ svg_text.fontSize } fill={ svg_text.fill } stroke={ svg_text.stroke } strokeWidth={ svg_text.strokeWidth } textAnchor={ svg_text.textAnchor }>
					{ svg_text.tspans != null ? svg_text.tspans.map( (tspan, index) => render_svg_tspan( tspan, index ) ) : null }
				</text>
			case "circle":
				return <circle key={svg_shape.id} id={ svg_shape.id } cx={svg_shape.cx} cy={svg_shape.cy} r={svg_shape.rx} ry={svg_shape.ry} fill={svg_shape.fill} opacity={svg_shape.opacity}/>
			default:
				console.log(svg_shape.shapeType+" not handled yet.")
		}

	})

	const container_style : React.CSSProperties = {
		marginTop : "3rem",
		marginBottom : "3rem"
	}

	const svg_style : React.CSSProperties = {
		width : "100%",
		height : height,
	}

	return (
		<div key={"svg_"+props.id} ref={ section_element } style={ container_style } >
			<svg id={ props.id } key={ props.id } ref={ svg_element } style={ svg_style } viewBox={ viewBox }>
				<g key={ props.id } ref={ svg_container_group } style={{ opacity:0 }}>
					{ props.svg != undefined ? props.svg.groups.map( (group, index) => render_svg_group( group, index ) ) : null }
					{ props.svg != undefined ? props.svg.shapes.map( (shape, index) => render_svg_shape( shape, index ) ) : null }
				</g>
			</svg>
		</div>
	)

}

export default SVGComponent
