import React, { useRef, useState, useEffect } from 'react'

interface BlogPlaceholderProps {
	string: string
	children : JSX.Element
}

interface BlogPlaceholderState {

}

export const BlogPlaceholder = ( props : BlogPlaceholderProps ) => {

	const container_style : React.CSSProperties = {
		width : "100%",
		display : "flex",
		flexDirection : "row",
		backgroundColor : "#eee",
	}

	const content_style : React.CSSProperties = {
		width : "100%",
		display : "flex",
		flexDirection : "row", 
		marginLeft : "auto",
		marginRight : "auto",
		justifyContent : "center",
		paddingLeft : "10%",
		paddingRight : "10%",
		textAlign : "center"
	}

	const text_style : React.CSSProperties = {
		display : "flex",
		width : "95%",
		flex : "row",
		textAlign : "left",
		paddingLeft : "5%",
		fontFamily : "'Milo Slab', Milo-Slab, san-serif",
		fontWeight : 300,
		fontSize : "1.1rem"
	}

	return (
		<section id="blog-placeholder" className="blog-placeholder" style={ container_style }>
			<div className="content" style={ content_style } >
				<svg id="Icons" xmlns="http://www.w3.org/2000/svg" width={50} viewBox="0 0 61.83 80"><path d="M21.01,29.58h0c-2.26,0-4.09-1.83-4.09-4.09h0c0-2.26,1.83-4.09,4.09-4.09h0c2.26,0,4.09,1.83,4.09,4.09h0c0,2.26-1.83,4.09-4.09,4.09Z" fill="none" stroke="#555" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/><line x1="31.88" y1="25.49" x2="44.91" y2="25.49" fill="none" stroke="#555" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/><path d="M21.01,44.5h0c-2.26,0-4.09-1.83-4.09-4.09h0c0-2.26,1.83-4.09,4.09-4.09h0c2.26,0,4.09,1.83,4.09,4.09h0c0,2.26-1.83,4.09-4.09,4.09Z" fill="none" stroke="#555" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/><line x1="31.88" y1="40.41" x2="44.91" y2="40.41" fill="none" stroke="#555" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/><path d="M21.01,59.42h0c-2.26,0-4.09-1.83-4.09-4.09h0c0-2.26,1.83-4.09,4.09-4.09h0c2.26,0,4.09,1.83,4.09,4.09h0c0,2.26-1.83,4.09-4.09,4.09Z" fill="none" stroke="#555" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/><line x1="31.88" y1="55.33" x2="44.91" y2="55.33" fill="none" stroke="#555" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/><rect x="1" y="1" width="59.83" height="78" rx="2.47" ry="2.47" transform="translate(61.83 80) rotate(180)" fill="none" stroke="#555" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/><path d="M15.52,1.36V9.73c0,1.99,1.64,3.61,3.67,3.61h23.47c2.03,0,3.67-1.61,3.67-3.61V1.36" fill="none" stroke="#555" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/><rect x="8.64" y="7.68" width="44.54" height="62.55" transform="translate(61.83 77.9) rotate(180)" fill="none" stroke="#555" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/></svg>
				<div style={ text_style }>
					{ props.children }
				</div>
			</div>
		</section>
	)

}

export default BlogPlaceholder