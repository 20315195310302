import { graphql } from "gatsby"
import React, { CSSProperties } from "react"
import Header from "../components/header"
import Layout from "../components/layout/layout"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import { FirebaseContext } from "../components/firebase/firebase-context"
import { logEvent, isSupported } from "firebase/analytics"

import SVG from "../components/blog/SVG"
import RadialHierarchy from "../components/blog/RadialHierarchy";
import ForceLayout from "../components/blog/ForceLayout";

import Link from "../components/blog/BlogLink"
import { H1, H2, H3, H4 } from "../components/blog/BlogHeaderLink"
import { BlogContents } from "../components/blog/BlogContents"
import { BlogInlineReference } from "../components/blog/BlogInlineReference"
import { LanguageSelector, LanguageContext, Language } from "../components/language/Language"
import Prism from 'prismjs';
import { ReifierModel } from "../components/blog/ReifierModel"
import { DraftWarning } from "../components/blog/BlogDraftHeader"
import { BlogComment } from "../components/blog/BlogComment"
import { BlogPlaceholder } from "../components/blog/BlogPlaceholder"

import 'prismjs/components/prism-swift';
import "../components/scss/blog.scss"

const shortcodes = { BlogPlaceholder, BlogComment, DraftWarning, ReifierModel, Prism, SVG, RadialHierarchy, ForceLayout, a:Link, h1:H1, h2:H2, h3:H3, h4:H4, BlogContents, BlogInlineReference, LanguageSelector }

export const MDXElements = ({ children }) => {

	return (
		<section id="blog-content" className="blog-outline">
			<MDXProvider components={shortcodes}>
				<MDXRenderer>{children}</MDXRenderer>
			</MDXProvider>
		</section>
	)
}


const isBrowser = typeof window !== "undefined"

export default class Template extends React.Component<any, any> {

	constructor(props) {
		super(props);
	}

	public render() {
		//console.log(this.props.data)

		// Deconstruct the props to get the data.
		const { mdx : { frontmatter, headings, body } } = this.props.data 

		return (
			<React.Fragment>
			<Header title={ frontmatter.title }/>
			<Layout>
			<FirebaseContext.Consumer>
			{
				firebase_context => (
					isBrowser && firebase_context.analytics !== undefined ? logEvent( firebase_context.analytics, "select_content", {
						content_type: "blog",
						item_id: frontmatter.title
					} ) : null,
					<React.Fragment>
						<article className="blog-post">
							<section className="blog-title-block">
								<h1>{ frontmatter.title }</h1>
								<p className="author">By: { frontmatter.author }</p>
								<p className="date">Published: <em>{ frontmatter.date }</em></p>
							</section>
							<BlogContents headings={ headings } is_draft={frontmatter.draft} />
							<MDXElements>{ body }</MDXElements>
						</article>
						{/* { Recent posts, or related posts component, with graphql query } */}
					</React.Fragment>)
			}
			</FirebaseContext.Consumer>
			</Layout>
			</React.Fragment>
		);
	}
}

export const pageQuery = graphql`
	query GetSinglePost($path: String) {
		mdx(frontmatter: { path: { eq: $path } }) {
			frontmatter {
				title
				date(formatString: "Do MMMM, YYYY")
				author
				draft
				tags
			}
			body
			headings {
				depth
				value
			}
		}
	}
`;
