import * as React from 'react';
import { useRef, useState, useEffect } from 'react'
import { Language, LanguageSelector, LanguageContext } from '../language/Language'

import { hierarchy as Hierarchy, HierarchyNode, HierarchyPointNode, tree as Tree, line, curveBumpX, cluster as Cluster } from 'd3'
import { SVGText } from '../svg/svg-text'
import { generate_id } from './BlogHeaderLink'

enum CirclePole {
	north = "north",
	south = "south",
	false = "false"
}

export enum ModelType {
	list           = "list",
	linked_list    = "linked_list",
	cluster        = "cluster",
	radial_cluster = "radial_cluster",
	radial_tree    = "radial_tree",
	tree           = "tree",
	indented_tree  = "indented_tree",
	directed_graph = "directed_graph",
	tree_map       = "tree_map",
	partition      = "partition",
	pack           = "pack",
	force          = "force",
	venn           = "venn",
}

interface NodePosition {
    x: number;
    y: number;
    parent_x: number;
    parent_y: number;
    angle: number;
}

interface MultiLingualString {
	[key: string] : string
	english : string
	svenska : string
}

export interface TextDictionary {
	[index: string]: number
}

export interface LineHeightDictionary {
	[index: string]: number
}

interface RadialHierarchyProps {
	language? : Language
	json : any
	rotation? : number
	levels? : number
}

interface RadialHierarchyState {
    width : number,
	height : number,
	viewBox : string,
}

export const RadialHierarchy = ( props:RadialHierarchyProps ) => {
	
	const node_text_boxes = useRef<TextDictionary>({})

	const div_element = useRef<HTMLDivElement>(null)
	const svg_element = useRef<SVGSVGElement>(null)

	//const [state, setState] = useState<{state: RadialHierarchyState}>();

	const [width, setWidth] = useState(0)
	const [height, setHeight] = useState(0)
	const [viewBox, setViewBox] = useState("0, 0, 0, 0")

	const [radius, setRadius] = useState(0)

	const [nodes, setNodes] = useState<HierarchyPointNode<any>[]>([])

	const [line_heights, set_line_heights] = useState<TextDictionary>({})

	//const [max_depth, setMaxDepth] = useState(1)

	// Mount / unmount useEffect
	useEffect( () => {

		let max_depth = props.levels !== undefined ? props.levels : 1

		// Add event listener.
		window.addEventListener( 'resize', update_the_size )
		
		// Run update the size on first load
		update_the_size()

		console.log(props.json)

		// Recursive function to convert component objects to arrays, for D3 hierarchy
		const recurse_child = ( d:any ) => {
			d.components = d.components !== undefined ? Object.values(d.components).map( recurse_child ) : null
			return d
		}

		var output = {
			"title": {
				english : "This needs to be updated with a prop",
				svenska : "This needs to be updated with a prop"
			},
			"components": Object.values(props.json).map( recurse_child )
		}

		let hierarchy = Hierarchy<any>(output, d => d.components)

		//console.log(hierarchy)

		let rad_tree = Cluster<any>()
		rad_tree.size( [360, width ] )
		
		rad_tree.separation( ( a:HierarchyNode<any>, b:HierarchyNode<any> ) => {
			return a.parent == b.parent && b.depth === max_depth && a.depth === max_depth ? 10 : 2
		})

		hierarchy.sum( (node) => {
			console.log(node)
			if ( node.parent === undefined ) {
				return 0
			}

			return node.components.length > 0 ? node.components.length : 1
		} )

		let graph = rad_tree( hierarchy )

		let nodes = graph.descendants().filter( (node, index) => {
			return node.depth <= max_depth ? true : false
		})

		setNodes( nodes )

		return () => {
			window.removeEventListener('resize', update_the_size)
		}

	}, [])

	const update_the_size = ( () => {

		let width = div_element !== undefined && div_element.current !== null ? div_element.current.clientWidth : 0
		let height = width / 16 * 9

		setWidth( width )
		setHeight( height )
		setViewBox( "0 0 "+ width + " "+ height )
		setRadius( width/3 )

	} )

	const project = ( node:HierarchyPointNode<any> ) => {

		let parent:NodePosition|null = node.depth > 1 && node.parent !== null ? project( node.parent ) : null

		let parent_x:number = parent !== null && node.depth > 1 ? parent.x : width / 2
		let parent_y:number = parent !== null && node.depth > 1 ? parent.y : height / 2

		let rotation = props.rotation !== undefined ? props.rotation : 0

		let angle = node.x / 180 * Math.PI - rotation

		let _radius = node.depth === 1 ? radius / 4 : radius / 4

		return {
			x : _radius * Math.cos( angle ) + parent_x, 
			y : _radius * Math.sin( angle ) + parent_y,
			parent_x : parent_x,
			parent_y : parent_y,
			angle : angle
		}
	}

	const get_text_height = ( ( key:string, height:number ) => {


		if ( key === undefined || key === null || key === "" ) {
			return
		}

		node_text_boxes.current[key] = height

		set_line_heights(node_text_boxes.current)

	})

	const get_quadrant = ( position: NodePosition, detect_in_circle : boolean = false ) => {
		
		if ( position.x == width/2 && position.y == height/2 ) {
			return 0
		}
		
		// 2 | 1
		// -----
		// 3 | 4

		if ( detect_in_circle ) {
			// Outside circle
			let val = Math.pow(( position.x - width/2 ), 2) + Math.pow(( position.y - height/2 ), 2)
			if ( val > Math.pow( radius, 2 ) ) {
				return -1
			}
		}

		// 1st quadrant
		if ( position.x > width/2 && position.y >= height/2 ) {
			return 1
		}

		// 2nd quadrant
		if ( position.x <= width/2 && position.y > height/2 ) {
			return 2
		}

		// 3rd quadrant
		if ( position.x < width/2 && position.y <= height/2 ) {
			return 3
		}

		// 4th quadrant
		if ( position.x >= width/2 && position.y < height/2 ) {
			return 4
		}

	}

	const get_text_position = ( anchor: string, position : NodePosition, node : HierarchyPointNode<any> ) => {

		let text_origin = 10
		let pole = get_pole( position )
		let key = generate_id(node.data.title[props.language])
		console.log(key)
		console.log(pole)

		switch (anchor) {
			case "middle":

				switch( pole ) {
					case CirclePole.north:

						let key = generate_id(node.data.title[props.language])
						let height = node_text_boxes.current[key]

						return {
							...position,
							x : text_origin * Math.cos( position.angle ) + position.x,
							y : height !== undefined ? text_origin * Math.sin( position.angle ) + position.y - height : text_origin * Math.sin( position.angle ) + position.y + 16,
						}

					case CirclePole.south:
						return {
							...position,
							x : text_origin * Math.cos( position.angle ) + position.x,
							y : text_origin * Math.sin( position.angle ) + position.y + 8,
						}
					case CirclePole.false:
						return {
							...position,
							x : text_origin * Math.cos( position.angle ) + position.x,
							y : text_origin * Math.sin( position.angle ) + position.y,
						}
				}

			case "end": 
				return {
					...position,
					x : 10 * Math.cos( position.angle ) + position.x, 
					y : 10 * Math.sin( position.angle ) + position.y - 10,
				}
			case "start":
				return {
					...position,
					x : 10 * Math.cos( position.angle ) + position.x, 
					y : 10 * Math.sin( position.angle ) + position.y - 10,
				}
			default:
				return position
		}

	}

	const get_line_width = ( anchor: string, position : NodePosition, node : HierarchyPointNode<any> ) => {

		let pole = get_pole( position )

		switch( pole ) {
			case CirclePole.north:

				let key = generate_id(node.data.title[props.language])
				let height = node_text_boxes.current[key]

				return 50

			case CirclePole.south:
				return 50
			case CirclePole.false:
				return 200
		}

	}

	const get_pole = ( position : NodePosition ) : CirclePole => {

		let delta = 5 * Math.PI/180

		if ( position.angle > ( Math.PI / 3 ) && position.angle < ( ( 2 * Math.PI ) / 3 ) ) {
			return CirclePole.south
		} else if ( position.angle > ( 4 * Math.PI / 3 ) && position.angle < ( 5 * Math.PI / 3 ) ) {
			return CirclePole.north
		}
		// } else if ( position.angle > (((3 * Math.PI) / 2) - delta) && position.angle < ((( 3 * Math.PI / 2 ) + delta )) ) {
		// 	return CirclePole.north
		// }
	
		return CirclePole.false

	}

	const get_text_anchor = ( node: HierarchyPointNode<any>, position : NodePosition ):string => {

		let max_depth = props.levels !== undefined ? props.levels : 1

		if ( node.depth === max_depth ) {

			let pole = get_pole(position)

			switch(pole) {
				case CirclePole.north: case CirclePole.south:
					return "middle"
				default:

			}

			let quadrant = get_quadrant(position)

			switch( quadrant ) {
				// Right hand side
				case 1: case 4:
					return "start"
				// Left hand side
				case 2: case 3:
					return "end"
			}

		} 

		return "middle"

	}

	const layout_nodes = () => {

		let line_generator = line()

		return nodes.map( ( node : HierarchyPointNode<any>, index : number ) => {

			// Project the position of the node
			let position = project(node)

			// Generate a key for the node
			let key = generate_id(node.data.title[props.language])
			
			// Generate the path for the line, skipping the first level.
			let path = node.depth > 1 ? line_generator( [ [position.parent_x, position.parent_y-4], [position.x, position.y - 4] ] ) : undefined

			let text_anchor = get_text_anchor(node, position)
			let text_position = get_text_position( text_anchor, position, node )
			let line_width = get_line_width( text_anchor, position, node )

			return index !== 0 ? (
				<g id={key+"-group"} key={key+"-group"}>
					{ path !== undefined ? <path key={"path-"+key} d={ path } opacity={0.3} stroke="black" fill={"none"}/> : null }
					<g>
						<circle
							id   = { "circle-"+key }
							key  = { "circle-"+key }
							r    = { 3 }
							cx   = { position.x }
							cy   = { position.y - 4 }
							fill = "rgb(230, 60, 38)"
							//onClick={ (event:React.MouseEvent) => this.props.edit_node( event, title ) }
							style={ { "cursor" : "pointer" } }
						/>
						{ node.depth <= 4 ? <SVGText 
							key={ key }
							reference={ node_text_boxes }
							x={ text_position.x }
							y={ text_position.y }
							string={ node.data.title[props.language] }
							get_height={ get_text_height }
							maxLineWidth={ line_width }
							fontSize={ 8 }
							fontLeading={ 0.9 }
							fill={"#333"}
							textAnchor={ text_anchor }
							fontFamily={ "'Milo Slab OT Regular', Milo-Slab, sans-serif" }
							//rotation={ text_position.angle  }
						/> : null }
					</g>
					
				</g>
			) : null

		})

	}

	return (
		<React.Fragment>
			<section ref={ div_element } id="radial-hierarchy" style={{ width : "100%" }}>
				<svg 
					ref={ svg_element }
					version="1.1" xmlns="http://www.w3.org/2000/svg" 
					xmlnsXlink="http://www.w3.org/1999/xlink" 
					style={ { width : "100%", height: "auto" } }
					viewBox={ viewBox } 
					>
					<g id="icf-rehab-set" key={"icf-rehab-set"}>
						{ layout_nodes() }
					</g>
				</svg>
			</section>
		</React.Fragment>
	)

}

export default RadialHierarchy