import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { logEvent } from "firebase/analytics"
import { FirebaseContext } from "../../components/firebase/firebase-context"

const isBrowser = typeof window !== "undefined"

export default function Link({ children, href }) {

	if ( href.startsWith('/') ) {
		// Use Gatsby's Link component for internal site navigation
		// to benefit from the preloading features
		// See: https://www.gatsbyjs.org/docs/gatsby-link/
		return(
			<React.Fragment>
				<FirebaseContext.Consumer>
				{ firebase_context => (
					isBrowser && firebase_context.analytics !== undefined ? logEvent( firebase_context.analytics, "page_view") : null,
					<GatsbyLink to={href}>{children}</GatsbyLink>
				)}
				</FirebaseContext.Consumer>
			</React.Fragment>
		)
	}

	// Check if the link is for a section on the page
	// We don't want to add the attributes for the on page links
	const onPage = href.startsWith('#');

	return(
		<React.Fragment>
			<FirebaseContext.Consumer>
			{ firebase_context => (
				isBrowser && firebase_context.analytics !== undefined ? logEvent( firebase_context.analytics, "click" ) : null,
				<a
					href={ href }
					// Open the link in a new page
					target={ onPage ? undefined : '_blank' }
					// Add noopener and noreferrer for security reasons
					rel={ onPage ? undefined : 'noopener noreferrer' }
				>
					{ children }
				</a>
			)}
			</FirebaseContext.Consumer>
		</React.Fragment>
	)
}