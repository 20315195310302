import React, { useRef, useState, useEffect } from 'react'

import { generate_id } from "./BlogHeaderLink"

import { logEvent } from "firebase/analytics"
import { FirebaseContext } from "../../components/firebase/firebase-context"
import { DraftWarning } from "./BlogDraftHeader"

interface BlogHeading {
	depth : number,
	value : string,
}

interface BlogContentsProps {
	headings : BlogHeading[]
	is_draft : boolean
}

interface BlogContentsState {

}

const isBrowser = typeof window !== "undefined"

export const BlogContents = ( props : BlogContentsProps ) => {

	const container_element = useRef(null);

	// Mount / unmount useEffect
	// useEffect( () => {
	
	// }, [])

	const generate_row = ( heading:BlogHeading ) => {

		let id = generate_id(heading.value)

		let style : React.CSSProperties = {
			paddingLeft : heading.depth === 2 ? 15 : heading.depth === 3 ? 30 : heading.depth === 4 ? 45 : 0,
			paddingTop : heading.depth === 1 ? 10 : heading.depth === 2 ? 5 : heading.depth === 3 ? 0 : 0
		}

		let font_style : React.CSSProperties = {
			fontFamily : "Milo Slab, Milo-Slab",
			fontWeight : heading.depth === 1 ? 700 : heading.depth === 2 ? 500 : heading.depth === 3 ? 400 : 300,
		}

		return (
			<FirebaseContext.Consumer key={id}>
			{ firebase_context => (
				isBrowser && firebase_context.analytics !== undefined ? logEvent( firebase_context.analytics, "select_content", {
					content_type: "blog",
					item_id: heading.value
				} ) : null,
				<div key={id} style={style}><a href={"#"+id} style={font_style}>{ heading.value }</a></div>
			)}
			</FirebaseContext.Consumer>
		)

	}

	const container_style : React.CSSProperties = {
		width : "100%",
		display : "flex",
		flexDirection : "row",
	}

	const content_style : React.CSSProperties = {
		width : "100%",
		display : "flex",
		flexDirection : "column", 
		marginLeft : "auto",
		marginRight : "auto",
		justifyContent : "center",
		backgroundColor : "#EEE",
	}

	return (
		<React.Fragment>
			{ props.is_draft !== undefined && props.is_draft === true ? <DraftWarning /> : null }
			<section id="blog-outline" className="blog-outline" ref={ container_element } style={ container_style }>
				<div className="content" style={ content_style } >
					<h3 style={ { fontFamily : "'Milo Slab OT Regular', Milo-Slab, sans-serif", color : "#333", fontWeight : 400, marginTop : 0, marginBottom : 25 } }>Outline</h3>
					{ props.headings.map( generate_row ) }
				</div>
			</section>
		</React.Fragment>
	)

}

export default BlogContents