import React, { useRef, useState, useEffect, ReactNode } from 'react'

interface LanguageSelectorProps {
	children? : ReactNode
}

interface LanguageSelectorState {
	language : Language
}

interface LanguageProviderProps {
	language : Language,
	switch_language : () => void
	children : any,
}

export enum Language {
	english = "english",
	svenska = "svenska"
}

export const LanguageContext = React.createContext( {
	language: Language.svenska,
	switch_language : () => {},
} )

LanguageContext.displayName = "LanguageContext"

export class LanguageProvider extends React.Component< LanguageSelectorProps, LanguageSelectorState> {
	
	constructor( props : LanguageProviderProps ) {
		super(props)
		this.state = {
			language: Language.svenska,
		}
	}

	// Mount / unmount useEffect
	componentDidMount() {

		let cached_language = localStorage.getItem("language")
		
		const language = cached_language !== null ? JSON.parse(cached_language) : undefined

		if ( language !== undefined && language !== null ) {
			this.setState({ language: language })
		} else {
			this.setState({ language: Language.svenska })
			localStorage.setItem("language", JSON.stringify(Language.svenska))
		}
	
	}

	switch_language = ( () => {
		let current = this.state.language == Language.svenska ? Language.english : Language.svenska
		localStorage.setItem("language", JSON.stringify(current))
		this.setState({ language: current })
	})

	render() {

		const { children } = this.props
		const { language } = this.state
		
		return (
			<LanguageContext.Provider
				value = {{
					language,
					switch_language : this.switch_language,
				}}
			>
				{ children }
			</LanguageContext.Provider>
		)
	}

}


// const LanguageSelector = ( { } : LanguageSelectorProps ) => {

// 	const container_element = useRef(null);
	
// 	// Set state
// 	const [state, setState] = useState<{ stateShape: LanguageSelectorState }>()
// 	const [language, setLanguage] = useState(Language.swedish)

// 	// Mount / unmount useEffect
// 	useEffect( () => {

// 		const language = JSON.parse(localStorage.getItem("language"))

// 		if ( language !== undefined ) {
// 			setLanguage(language)
// 		} else {
// 			setLanguage(Language.swedish)
// 			localStorage.setItem("language", Language.swedish)
// 		}
	
// 	}, [])

// 	const switch_language = ( () => {
// 		let current = language == Language.swedish ? Language.english : Language.swedish
// 		localStorage.setItem("language", JSON.stringify(current))
// 		setLanguage(current)
// 	})

// 	const container_style : React.CSSProperties = {
// 		width : "100%",
// 		display : "flex",
// 		flexDirection : "row",
// 		marginLeft : "auto",
// 		marginRight : "auto",
// 		justifyContent : "center",
// 	}

// 	return (
// 		<section ref={ container_element } id="language-selector" style={ container_style }>
// 			<h5>
// 				{ language === Language.english ? "Byt språk" : "Change the language" }
// 			</h5>
// 			<button onClick={ (event) => switch_language() } disabled={ language === Language.swedish}>Svenska</button>
// 			<button onClick={ (event) => switch_language() } disabled={ language === Language.english}>English</button>
// 		</section>
// 	)

// }

export const LanguageSelector = ( { } : LanguageSelectorProps ) => {

	const container_element = useRef(null);

	const container_style : React.CSSProperties = {
		width : "100%",
		display : "flex",
		flexDirection : "row",
		marginLeft : "auto",
		marginRight : "auto",
		justifyContent : "center",
		alignItems : "center",
	}

	return (
		<LanguageContext.Consumer>
			{ context => (
				<section ref={ container_element } id="language-selector" className="language-selector" style={ container_style }>
					<p style={{ paddingRight : "20px", color : "#000", maxWidth: "200px", marginTop : "20px", marginBottom : "20px" }}>
						{ context.language === Language.english ? "Ändra språket" : "Change the language" }
					</p>
					<button onClick={ (event) => context.switch_language() } disabled={ context.language === Language.svenska}>Svenska</button>
					<button onClick={ (event) => context.switch_language() } disabled={ context.language === Language.english}>English</button>
				</section>
			)}
		</LanguageContext.Consumer>
	)

}
